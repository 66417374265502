import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Avatar,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import { connect } from 'react-redux';
import { Edit } from '@material-ui/icons';
import { toMoney } from '../../../../shared/utils/parsers';
import discountPermition from '../../../../shared/utils/discountPermition';
import CheckoutItemDiscount from './CheckoutItemDiscount';
import CheckoutItemDetails from './CheckoutItemDetails';
import CheckoutItemRemove from './CheckoutItemRemove';
import ModalTrocaValor from './ModalTrocaValor';
import pplImage from '../../../../assets/ppl.png';
import CheckoutItemSelect from './CheckoutItemSelect';
import algoliasearch from "algoliasearch";
import {DetailsDialog, envVariablesAlgolia} from "../../../../shared/components/algolia";
import useStyles from './style';

const CheckoutItem = ({
  productId, name, image, quantity, total, totalOnTime, subTotalOnTime, margin,
  updateItem, drawerIsOpened, onSubmit, loading, discount, subTotal, checkoutId, 
  checkout, updateWarranty, onRemove, allowedStock, ppl, pplHash, pplStock, enqueueSnackbar, 
  companyName, idSeller, model, idItemBseller, showItem, typeStock, deliveryTime, shippingCompany,
  orderId, filials, activeFilial, isPma, freight, codKit, garantias, user, virtualStorage, 
  discountPercent, onItemsSelected, selectedItems, isSingleDelivery, currentFilial
}) => {
  const { appId, writeApiKey, indexName, } = envVariablesAlgolia;
  const searchClient = algoliasearch(appId, writeApiKey);
  const indexAlgolia = searchClient.initIndex(indexName);

  const { breakpoints } = useTheme();
  const classes = useStyles({ drawerIsOpened });
  const [amount, setAmount] = useState(discount);
  const [initialPercent, setInitialPercent] = useState(discountPercent);
  const [percent, setPercent] = useState(initialPercent);
  const [justify, setJustify] = useState('');
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    setAmount(discount);
    setPercent(discountPercent);
    setJustify('');
  }, [subTotal, initialPercent, subTotalOnTime]);

  const discountPermission = user.permissoes.find((permission) => (
    permission.id === 8
  ));

  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const showProductDetails = () => {
    indexAlgolia.search('', {
      filters: `objectID:${productId}`,
    })
      .then(({hits}) => {
        if(hits.length) {
          setProduct(hits[0]);
        }
      })
      .catch((error) => console.log('error', error));
  }

  useEffect(() => {
    if (product.objectID) {
      setOpenDetailsDialog(true);
    }
  }, [product]);

  return (
    <>
      <div className={classes.wrapper}>
        <Box display="flex">
          <Grid container>
            <CheckoutItemDetails
              typeStock={typeStock}
              currentFilial={currentFilial}
              isSingleDelivery={isSingleDelivery}
              productId={productId}
              checkoutId={checkoutId}
              name={name}
              image={image}
              allowedStock={allowedStock}
              quantity={quantity}
              subTotalOnTime={subTotalOnTime}
              total={totalOnTime}
              updateItem={updateItem}
              updateWarranty={updateWarranty}
              onSubmit={onSubmit}
              drawerIsOpened={drawerIsOpened}
              loading={loading}
              ppl={ppl}
              pplHash={pplHash}
              virtualStorage={virtualStorage}
              shippingCompany={shippingCompany}
              pplStock={pplStock}
              codKit={codKit}
              enqueueSnackbar={enqueueSnackbar}
              showItem={showProductDetails}
              companyName={companyName}
              idSeller={idSeller}
              model={model}
              idItemBseller={idItemBseller}
              filials={filials}
              activeFilial={activeFilial}
              setAmount={setAmount}
              amount={amount}
              setPercent={setPercent}
              justify={justify}
              orderId={orderId}
              garantias={garantias}
              percent={percent}
            />

            <Grid
              item
              container
              lg={5}
              md={drawerIsOpened ? 12 : 6}
              xs={12}
              className={classes.infoContainer}
              alignItems="center"
            >

              <div className={classes.productPrices}>
                <div className={classes.pricesContainer}>
                    <div className={classes.groupPrice} style={{marginBottom: ''}}>
                      {ppl
                        && (
                        <img src={pplImage} alt="Preço Exclusivo por Link" />
                        )}
                      {!isPma
                        ? (
                          <CheckoutItemDiscount
                            productId={productId}
                            subTotalOnTime={subTotalOnTime}
                            discount={discount}
                            percent={percent}
                            total={totalOnTime}
                            amount={amount}
                            onSubmit={onSubmit}
                            setJustify={setJustify}
                            setAmount={setAmount}
                            setPercent={setPercent}
                            initialPercent={discountPercent}
                            loading={loading}
                            ppl={ppl}
                            quantity={quantity}
                            isPma={isPma}
                            virtualStorage={virtualStorage}
                            justify={justify}
                            idSeller={idSeller}
                            orderId={orderId}
                            payments={checkout?.payments}
                          />
                        )
                        : (
                          <Box>
                            <span style={{ fontSize: 10, fontWeight: 600 }}>
                              <span>Desconto Indisponivel </span>
                              <Tooltip title="Desconto indisponivel devido ao produto ser PMA">
                                <span style={{ cursor: 'pointer' }}>
                                  [?]
                                </span>
                              </Tooltip>
                            </span>
                          </Box>
                        )}
                    </div>

                  { !isMobile && (
                  <div className={classes.groupPrice}>

                    {['V', 'X'].includes(typeStock) && (
                      <Box ml={2}>
                        <Tooltip title={
                          `Tipo estoque: ${{
                            X: 'CROSSDOCKING',
                            V: 'VIRTUAL',
                          }[typeStock]
                          || typeStock}`
                        }
                        >
                          <Avatar className={classes.stock}>
                            {typeStock === 'X' ? 'C' : typeStock}
                          </Avatar>
                        </Tooltip>
                      </Box>
                    )}
                  </div>
                  )}

                </div>
                  <div style={{display: 'inherit', width: '40%', textAlign: 'left'}}>
                    <div className={classes.groupPrice}>
                      <Typography variant="subtitle2" className={classes.currentPrice}>
                        De: <span style={{textDecoration: 'line-through'}}>{`${toMoney(totalOnTime)}`}</span>
                        <br />
                        <Typography variant="subtitle2" className={classes.oldPrice} style={{fontWeight: 700}}>
                          Por: {`${toMoney(total)}`}
                          <Edit onClick={handleOpenModal} size="small" style={{marginLeft: 6, height: 16, cursor: 'pointer'}} />
                        </Typography>

                      </Typography>
                    </div>
                  </div>

                  <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                    <Box width={30}>
                      {!selectedItems.length > 0 && (
                        <CheckoutItemRemove
                          checkoutId={checkoutId}
                          productId={productId}
                          justify={justify}
                          percent={percent}
                          amount={amount}
                          subTotalOnTime={subTotalOnTime}
                          onRemove={onRemove}
                          loading={loading}
                          />
                      )}
                    </Box>
                    <CheckoutItemSelect checked={selectedItems.find(e => e === productId) != null} value={productId} onChange={onItemsSelected}/>
                  </div>

              </div>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mt={1}
                style={{marginTop: 0, marginLeft: 8}}
              >
                <Typography
                  variant="body1"
                  className={classes.model}
                  dangerouslySetInnerHTML={{
                    __html:
                      `${shippingCompany || ''}
                        ${checkout.descricaoEntregaEstimada 
                          ? `- ${checkout.descricaoEntregaEstimada}` 
                          : typeof deliveryTime === 'number'
                          ? ` - Prazo de entrega: ${deliveryTime} dia${deliveryTime > 1 ? 's' : ''
                        }
                      ` : ''
                      } - Frete: ${freight > 0
                        ? `${toMoney(freight)}`
                        : `<span style="color: ${green[500]}">GRATIS</span>`
                      }`,
                  }}
                />

                {isPma && (
                  <Typography variant="body2" className={classes.pma}>
                    Produto é PMA
                  </Typography>
                )}

                <Typography variant="body1" style={{textAlign: 'right', marginRight:11}} className={classes.model2}>
                  {`${margin}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>

      <ModalTrocaValor
        open={open}
        setOpen={setOpen}
        discountPermission={discountPermission}
        valoraPrazo={subTotalOnTime}
        codProduto={productId}
        margin={margin}
        percent={percent}
        checkoutId={checkoutId}
        idItemBseller={idItemBseller}
        justify={justify}
        amount={amount}
      />

      <DetailsDialog
        setOpen={setOpenDetailsDialog}
        open={openDetailsDialog}
        product={product}
        pplInfo={product.ppl || null}
        outsideSearch
      />
    </>
  );
};

CheckoutItem.defaultProps = {
  companyName: null,
  idSeller: null,
  ppl: false,
  pplHash: null,
  pplStock: null,
  shippingCompany: null,
  deliveryTime: null,
  pointsOnTime: 0,
  isPma: false,
  orderId: null
};

CheckoutItem.propTypes = {
  subTotal: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  allowedStock: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  discountPercent: PropTypes.number.isRequired,
  pointsOnTime: PropTypes.number,
  checkoutId: PropTypes.string.isRequired,
  checkout:PropTypes.instanceOf(Object).isRequired,
  total: PropTypes.number.isRequired,
  totalOnTime: PropTypes.number.isRequired,
  subTotalOnTime: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  updateItem: PropTypes.func.isRequired,
  updateWarranty: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  drawerIsOpened: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  ppl: PropTypes.bool,
  isPma: PropTypes.bool,
  virtualStorage: PropTypes.bool,
  pplHash: PropTypes.string,
  pplStock: PropTypes.number,
  freight: PropTypes.number.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  idSeller: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.string.isRequired,
  idItemBseller: PropTypes.number.isRequired,
  typeStock: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number,
  shippingCompany: PropTypes.string,
  filials: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  activeFilial: PropTypes.instanceOf(Object).isRequired,
  orderId: PropTypes.number,
  codKit: PropTypes.number.isRequired,
  canDiscount: PropTypes.bool.isRequired,
  garantias: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  user: PropTypes.instanceOf(Object).isRequired,
  onItemsSelected: PropTypes.func.isRequired,
  selectedItems: PropTypes.bool,
  isSingleDelivery: PropTypes.bool,
  currentFilial: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => ({
  ...state.main.app,
  user: state.auth.login.user,
  canDiscount: discountPermition(state.auth.login.user.idVendedor),
});

export default connect(mapState)(CheckoutItem);
