import { handleActions, combineActions } from 'redux-actions';
import { Types } from './index';
import { getClient } from '../../../shared/services/authClient';

const INITIAL_STATE = {
  clients: null,
  client: getClient(),
  page: 0,
  isLoading: false,
  hasException: null,
  clientMatrixId: 0,
};

const stopLoading = combineActions(
  Types.CLIENT_SIGN_IN_FAILURE,
  Types.SET_CLIENT_FAILURE,
  Types.SET_CLIENT_MATRIX_ID,
);

const startLoading = combineActions(
  Types.CLIENT_SIGN_IN_REQUEST,
  Types.SET_CLIENT_REQUEST,
);

export default handleActions({
  [stopLoading]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [startLoading]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [Types.CLIENT_SIGN_IN_SUCCESS]: (state, action) => {
    const { clients } = action.payload;
    return {
      ...state,
      clients,
      page: 0,
      isLoading: false,
    };
  },
  [Types.SET_CLIENT_SUCCESS]: (state, action) => {
    const { client, clients } = action.payload;
    return {
      ...state,
      client,
      clients,
      page: 0,
      isLoading: false,
    };
  },
  [Types.SET_CLIENT_MATRIX_ID]: (state, action) => {
    const { clientMatrixId } = action.payload;
    return {
      ...state,
      clientMatrixId,
      page: 0,
      isLoading: false,
    };
  },
}, INITIAL_STATE);
