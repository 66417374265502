/* eslint-disable no-restricted-syntax */
import types from './types';
import {
	getCheckoutDetails,
	updateItem,
	checkoutDiscount,
	checkoutRequestDiscount,
	checkDiscountOffer,
	itemDiscount,
	removeItem,
	saveExtraInfo,
	insertCoupon,
	deleteCoupon,
	checkCoupon,
	submitOrder,
	getPdf,
	sendEmail,
	addAllVouchers,
	tradeValueProduct,
	removeItemsList,
} from '../../shared/repositories/checkoutRepository';
import resolveError from '../../shared/utils/resolveError';
import { replace } from '../../history';
import apiService from '../../shared/services/apiService';
import { setFilialVendedor, getFilialVendedor, setUnidadeDeNegocio, getUnidadeDeNegocio, setCanalDeVendas } from '../../shared/services/app';
// eslint-disable-next-line import/no-cycle
import { setPaymentMethodAction } from './_components/payment/actions';
import { setLogoutCustomer } from '../../shared/services/app';
import { getFreight, persistFreight, removeFreight } from '../../shared/services/checkoutService';
import {Types, Types as TypesClient} from "../authClient/login";
import {clearClient, getClient, setClient} from "../../shared/services/authClient";

export const showAddInfoAction = () => ({ type: types.SHOW_ADD_INFO });
export const hideAddInfoAction = () => ({ type: types.HIDE_ADD_INFO });
export const hideInvalidDialogAction = () => ({ type: types.HIDE_INVALID_DIALOG });

export const getCheckoutAction =
	({
		idCheckout,
		isSameDay,
		isPickup = false,
		notCalculaFrete,
		startRequest,
		calculateTax = false,
		originFromTax = false,
		forcarFrete = false,
		entregaNitro = false,
		entregaAgendada = false,
		dataEntregaAgendada = ""
	}) =>
	async (dispatch, getState) => {
		dispatch({ type: types.GET_CHECKOUT_REQUEST });
		try {
			const { filial } = getState().main.app;
			const freightType = getFreight(idCheckout);

			const { forceFreight: forceFreight } = getState().main.checkout.geral.checkout;

			if (forcarFrete == undefined || forcarFrete == null) {
				forcarFrete = forceFreight;
			}

			const {
				user: { idVendedor },
			} = getState().auth.login;
			if (
				getFilialVendedor() == null ||
				getFilialVendedor() == undefined ||
				getUnidadeDeNegocio() == null ||
				getUnidadeDeNegocio() == undefined
			) {
				const res = await apiService.get(`/vendedores/${idVendedor}`);
				const {
					data: { results: r },
				} = res;
				setFilialVendedor(r.idFilial);
				setUnidadeDeNegocio(r.idUnidadeDeNegocio);
				setCanalDeVendas(r.idCanalDeVendas)
			}

			let checkout = await getCheckoutDetails({
				idCheckout,
				isSameDay: startRequest ? freightType === 'isSameDay' : isSameDay,
				isPickup: startRequest ? freightType === 'isPickup' : isPickup,
				entregaNitro: startRequest ? freightType === 'entregaNitro' : entregaNitro,
				entregaAgendada: startRequest ? freightType === 'entregaAgendada' : entregaAgendada,
				dataEntregaAgendada,
				idEstabelecimento: filial.idEstabelecimento,
				notCalculaFrete,
				calculateTax,
				originFromTax,
				forcarFrete,
			});

			if (checkout.checkedNitrous && !startRequest) {
				persistFreight({
					freightType: 'entregaNitro',
					checkoutId: idCheckout,
				});
			} else if (checkout.checkedSchedule && !startRequest) {
				persistFreight({
					freightType: 'entregaAgendada',
					checkoutId: idCheckout,
				});
				} else if (checkout.checkedNormal && !startRequest) {
				persistFreight({
					freightType: 'isNormal',
					checkoutId: idCheckout,
				});
			} else if (checkout.checkedPickup && !startRequest) {
				persistFreight({
					freightType: 'isPickup',
					checkoutId: idCheckout,
				});
			} else if (checkout.checkedSameday && !startRequest) {
				persistFreight({
					freightType: 'isSameDay',
					checkoutId: idCheckout,
				});
			}
			const { snack } = getState().main.app;

			let reloadCheckout = false;
			for (const group of checkout.itemsFilials) {
				for (const item of group.items) {
					let reloadItem = false;
					if (item.ppl) {
						if (!item.pplActive) {
							reloadCheckout = true;
							reloadItem = true;
							snack.enqueueSnackbar(`Preço exclusivo por link inativo (${item.pplHash})`, {
								variant: 'warning',
							});
						}

						if (item.pplStock < item.quantity) {
							reloadCheckout = true;
							reloadItem = true;
							snack.enqueueSnackbar(`Preço exclusivo sem estoque (${item.pplHash})`, {
								variant: 'warning',
							});
						}

						if (item.pplExpirated) {
							reloadCheckout = true;
							reloadItem = true;
							snack.enqueueSnackbar(`Preço exclusivo expirado (${item.pplHash})`, {
								variant: 'warning',
							});
						}

						if (reloadItem) {
							// eslint-disable-next-line
							await updateItem({
								idCheckout,
								productId: item.productId,
								quantity: item.quantity,
							});
						}
					}

					if (item.idSeller && item.allowedStock === 0) {
						reloadCheckout = true;
						snack.enqueueSnackbar(`${item.name} esgotado, removendo do checkout`, {
							variant: 'warning',
						});
						// eslint-disable-next-line no-await-in-loop
						await removeItem({ checkoutId: idCheckout, productId: item.productId });
					}
				}
			}

			if (reloadCheckout) {
				checkout = await getCheckoutDetails({
					idCheckout,
					isSameDay,
					isPickup,
					idEstabelecimento: filial.idEstabelecimento,
					notCalculaFrete,
					calculateTax,
					originFromTax,
					forcarFrete,
					entregaNitro,
					entregaAgendada
				});
			}

			dispatch({
				type: types.GET_CHECKOUT_SUCCESS,
				payload: {
					checkout,
					chosedFreight: !!freightType,
				},
			});

			if (startRequest) {
				const invalidDialog = {
					document: !checkout.customer.document,
					address: !checkout.customer.address.zipCode,
				};

				if (Object.values(invalidDialog).some(Boolean)) {
					dispatch({
						type: types.SHOW_INVALID_DIALOG,
						payload: { invalidDialog },
					});
				}
			}

			dispatch(setPaymentMethodAction(checkout.resume));
		} catch (e) {
			const { snack } = getState().main.app;
			snack.enqueueSnackbar(resolveError(e), {
				variant: 'error',
			});
			dispatch({ type: types.GET_CHECKOUT_FAIL });
		}
	};

export const resetCheckoutAction = () => ({ type: types.RESET_CHECKOUT });

export const updateQuantityAction =
	({ idCheckout, productId, quantity, pplHash, warrantyId, porcentagemDesconto, total }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.UPDATE_ITEM_REQUEST });
		try {
			const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
			const { calculateTax } = getState().main.checkout.geral.checkout.resume;

			await updateItem({
				idCheckout,
				productId,
				quantity,
				pplHash,
				warrantyId,
			});

			dispatch(
				getCheckoutAction({
					idCheckout: idCheckout,
					isSameDay: checkedSameday,
					isPickup: checkedPickup,
					entregaNitro: checkedNitrous,
					entregaAgendada: checkedSchedule,
					dataEntregaAgendada: dateSchedule,
					calculateTax,
				})
			);

			dispatch({
				type: types.UPDATE_ITEM_SUCCESS,
			});
		} catch (e) {
			const { snack } = getState().main.app;
			snack.enqueueSnackbar(resolveError(e), {
				variant: 'error',
			});
			dispatch({ type: types.UPDATE_ITEM_FAIL });
		}
	};

export const updateWarrantyAction =
	({ idCheckout, productId, quantity, pplHash, warrantyId, porcentagemDesconto, total }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.UPDATE_ITEM_REQUEST });
		try {
			const { checkedSameday: isSameDay, checkedPickup: isPickup } = getState().main.checkout.geral.checkout;
			const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;

			const { calculateTax } = getState().main.checkout.geral.checkout.resume;
			const { filial } = getState().main.app;

			await updateItem({
				idCheckout,
				productId,
				quantity,
				pplHash,
				warrantyId,
			});

			dispatch(
				getCheckoutAction({
					idCheckout: idCheckout,
					isSameDay: checkedSameday,
					isPickup: checkedPickup,
					entregaNitro: checkedNitrous,
					entregaAgendada: checkedSchedule,
					dataEntregaAgendada: dateSchedule,
					calculateTax,
				})
			);

			dispatch({
				type: types.UPDATE_ITEM_SUCCESS,
			});
		} catch (e) {
			const { snack } = getState().main.app;
			snack.enqueueSnackbar(resolveError(e), {
				variant: 'error',
			});
			dispatch({ type: types.UPDATE_ITEM_FAIL });
		}
	};

export const removeProductAction = (checkoutId, productId) => async (dispatch, getState) => {
	dispatch({ type: types.REMOVE_ITEM_REQUEST });

	try {
		const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		await removeItem({ checkoutId, productId });

		dispatch({ type: types.REMOVE_ITEM_SUCCESS });
		dispatch(
			getCheckoutAction({
				idCheckout: checkoutId,
				isSameDay: checkedSameday,
				isPickup: checkedPickup,
				entregaNitro: checkedNitrous,
				entregaAgendada: checkedSchedule,
				dataEntregaAgendada: dateSchedule,
				calculateTax,
			})
		);
	} catch (e) {
		const { snack } = getState().main.app;
		snack.enqueueSnackbar(resolveError(e), {
			variant: 'error',
		});
		dispatch({ type: types.REMOVE_ITEM_FAIL });
	}
};

export const removeProductListAction = (checkoutId, selectedItems) => async (dispatch, getState) => {
	dispatch({ type: types.REMOVE_ITEM_REQUEST });

	try {
		const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		await removeItemsList({ checkoutId, selectedItems });

		dispatch({ type: types.REMOVE_ITEM_SUCCESS });
		dispatch(
			getCheckoutAction({
				idCheckout: checkoutId,
				isSameDay: checkedSameday,
				isPickup: checkedPickup,
				entregaNitro: checkedNitrous,
				entregaAgendada: checkedSchedule,
				dataEntregaAgendada: dateSchedule,
				calculateTax,
			})
		);
	} catch (e) {
		const { snack } = getState().main.app;
		snack.enqueueSnackbar(resolveError(e), {
			variant: 'error',
		});
		dispatch({ type: types.REMOVE_ITEM_FAIL });
	}
};

export const checkoutDiscountAction = form => async (dispatch, getState) => {
	dispatch({ type: types.CHECKOUT_DISCOUNT_REQUEST });
	const { snack } = getState().main.app;
	try {
		const { checkoutId, customer, checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		const { user } = getState().auth.login;

		const { message } = await checkoutDiscount(checkoutId, {
			...form,
			checkoutId: checkoutId,
			idVendedor: user.idVendedor,
		});
		snack.enqueueSnackbar(message, { variant: 'success' });
		dispatch({ type: types.CHECKOUT_DISCOUNT_SUCCESS });
		await dispatch(
			getCheckoutAction({
				idCheckout: checkoutId,
				isSameDay: checkedSameday,
				isPickup: checkedPickup,
				entregaNitro: checkedNitrous,
				entregaAgendada: checkedSchedule,
				dataEntregaAgendada: dateSchedule,
				calculateTax,
			})
		);
		return true;
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), {
			variant: 'error',
		});
		dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
		return false;
	}
};

export const checkoutRequestDiscountAction = form => async (dispatch, getState) => {
	dispatch({ type: types.CHECKOUT_DISCOUNT_REQUEST });
	const { snack } = getState().main.app;
	try {
		const { checkoutId, customer, checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		const { user } = getState().auth.login;

    const { message } = await checkoutRequestDiscount(checkoutId, {
      ...form,
      checkoutId: checkoutId,
      clientId: customer.customerId,
      userId: user.idVendedor,
    });

    await dispatch(getCheckoutAction({
      idCheckout: checkoutId,
      isSameDay: checkedSameday,
      isPickup: checkedPickup,
      entregaNitro: checkedNitrous,
			entregaAgendada: checkedSchedule,
			dataEntregaAgendada: dateSchedule,
      calculateTax,
    }));

		snack.enqueueSnackbar(message, { variant: 'success' });
		dispatch({ type: types.CHECKOUT_DISCOUNT_SUCCESS });

		return true;
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), {
			variant: 'error',
		});
		dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
		return false;
	}
};

export const checkDiscountOfferAction = () => async (dispatch, getState) => {
  dispatch({ type: types.CHECKOUT_DISCOUNT_REQUEST });
  const { snack } = getState().main.app;
  try {

    const {
      checkoutId, customer, checkedSameday, checkedPickup,
    } = getState().main.checkout.geral.checkout;
    const { calculateTax } = getState().main.checkout.geral.checkout.resume;
    const { user } = getState().auth.login;

    const statusTexto = await checkDiscountOffer(checkoutId);
    const message = statusTexto;

    if (statusTexto.includes("Não existe nenhuma solicitação de aprovação")){
      dispatch({ type: types.OPEN_DIALOG_DISCOUNT_OBS});
      dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
      return false;
    }

    if (statusTexto.includes("aguardando aprovação")){
      dispatch({ type: types.OPEN_DIALOG_DISCOUNT_REQUEST});
      dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
      return false;
    }

    if (statusTexto.includes("reprovada")){
      dispatch({ type: types.OPEN_DIALOG_DISCOUNT_OBS});
      dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
      return false;
    }

		dispatch({ type: types.CHECKOUT_DISCOUNT_SUCCESS });
		return true;
	} catch (e) {
		snack.enqueueSnackbar('', {
			variant: 'error',
		});

		dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
		return false;
	}
};

export const checkDiscountOfferRequestAction = () => async (dispatch, getState) => {
	dispatch({ type: types.CHECKOUT_DISCOUNT_REQUEST });
	const { snack } = getState().main.app;
	try {
		const { checkoutId, customer, checkedSameday, checkedPickup } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		const { user } = getState().auth.login;

		const statusTexto = await checkDiscountOffer(checkoutId);
		const message = statusTexto;

		dispatch({
			type: types.OPEN_DIALOG_DISCOUNT_OBS_DESCRIPTION,
			description: statusTexto,
		});

		dispatch({ type: types.CHECKOUT_DISCOUNT_SUCCESS });
		return true;
	} catch (e) {
		dispatch({
			type: types.OPEN_DIALOG_DISCOUNT_OBS_DESCRIPTION,
			description: '',
		});
		dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });

		return false;
	}
};

export const removeAllDiscountAction = () => async (dispatch, getState) => {
	dispatch({ type: types.CHECKOUT_DISCOUNT_REQUEST });
	const { snack } = getState().main.app;
	try {
		const { checkoutId, customer, checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		const { user } = getState().auth.login;

		const { message } = await checkoutDiscount(checkoutId, {
			valorDesconto: 0,
			percentualDesconto: 0,
			observacaoSolicitacao: '',
			codCliente: customer.customerId,
			codVendedor: user.idVendedor,
		});
		await dispatch(
			getCheckoutAction({
				idCheckout: checkoutId,
				isSameDay: checkedSameday,
				isPickup: checkedPickup,
				entregaNitro: checkedNitrous,
				entregaAgendada: checkedSchedule,
				dataEntregaAgendada: dateSchedule,
				calculateTax,
			})
		);
		return true;
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), {
			variant: 'error',
		});
		dispatch({ type: types.CHECKOUT_DISCOUNT_FAIL });
		return false;
	}
};

export const itemDiscountAction =
	({ productId, amount, justify, total, porcentagemDesconto, quantidade }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.ITEM_DISCOUNT_REQUEST });
		const { snack } = getState().main.app;
		try {
			const { checkoutId, customer, checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
			const { calculateTax } = getState().main.checkout.geral.checkout.resume;
			const { user } = getState().auth.login;

			const { message } = await itemDiscount(checkoutId, productId, {
				valorDesconto: amount,
				valorTotal: total,
				porcentagemDesconto,
				checkoutId: checkoutId,
				codProduto: productId,
				idVendedor: user.idVendedor,
				quantidade,
			});
			/*if (discountMessage === undefined) {
      snack.enqueueSnackbar(message, { variant: 'success' });
    }*/
    dispatch({ type: types.ITEM_DISCOUNT_SUCCESS });
    dispatch(getCheckoutAction({
      idCheckout: checkoutId,
      isSameDay: checkedSameday,
      isPickup: checkedPickup,
      entregaNitro: checkedNitrous,
			entregaAgendada: checkedSchedule,
			dataEntregaAgendada: dateSchedule,
      calculateTax,
    }));



  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), {
      variant: 'error',
    });
    dispatch({ type: types.ITEM_DISCOUNT_FAIL });
  }
};

export const saveExtraInfoAction = (checkoutId, body) => async (dispatch, getState) => {
	dispatch({ type: types.UPDATE_INFO_REQUEST });
	const { snack } = getState().main.app;

	try {
		const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		const message = await saveExtraInfo(checkoutId, body);
		snack.enqueueSnackbar(message, { variant: 'success' });

		dispatch({ type: types.UPDATE_INFO_SUCCESS });
		dispatch(
			getCheckoutAction({
				idCheckout: checkoutId,
				isSameDay: checkedSameday,
				isPickup: checkedPickup,
				entregaNitro: checkedNitrous,
				entregaAgendada: checkedSchedule,
				dataEntregaAgendada: dateSchedule,
				calculateTax,
			})
		);
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.UPDATE_INFO_FAIL });
	}
};

export const insertCouponAction =
	({ idCheckout, idCoupon, hashCupom, idVale, idValeCliente, price, remainder, idClient, tipoVale }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.INSERT_COUPON_REQUEST });
		const { snack } = getState().main.app;

  try {
    const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
    const { calculateTax } = getState().main.checkout.geral.checkout.resume;
    const resultsCp = await insertCoupon({
      idCheckout, idCoupon, hashCupom, idVale, idValeCliente, price, remainder, idClient, tipoVale,
    });

			if (resultsCp.value?.isValProdMaiorQueValMaxCupom == true) {
				snack.enqueueSnackbar(resultsCp.message ? resultsCp.message : 'Cupom/Vale inserido com sucesso', { variant: 'warning' });
			} else {
				snack.enqueueSnackbar(resultsCp.message ? resultsCp.message : 'Cupom/Vale inserido com sucesso', { variant: 'success' });
			}

			dispatch({ type: types.INSERT_COUPON_SUCCESS });
			dispatch({ type: types.COUPON_VALE_LIST });
			dispatch(
				getCheckoutAction({
					idCheckout,
					isSameDay: checkedSameday,
					isPickup: checkedPickup,
					entregaNitro: checkedNitrous,
					entregaAgendada: checkedSchedule,
					dataEntregaAgendada: dateSchedule,
					calculateTax,
				})
			);
		} catch (e) {
			snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
			dispatch({ type: types.INSERT_COUPON_FAIL });
		}
	};

export const deleteCouponAction =
	({ idCheckout }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.INSERT_COUPON_REQUEST });
		const { snack } = getState().main.app;

		try {
			const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
			const { calculateTax } = getState().main.checkout.geral.checkout.resume;
			await deleteCoupon({ idCheckout });

			snack.enqueueSnackbar('Cupom removido com sucesso', { variant: 'success' });

			dispatch({ type: types.INSERT_COUPON_SUCCESS });

			dispatch(
				getCheckoutAction({
					idCheckout,
					isSameDay: checkedSameday,
					isPickup: checkedPickup,
					entregaNitro: checkedNitrous,
					entregaAgendada: checkedSchedule,
					dataEntregaAgendada: dateSchedule,
					calculateTax,
				})
			);
		} catch (e) {
			snack.enqueueSnackbar(e.message || e, { variant: 'error' });
			dispatch({ type: types.INSERT_COUPON_FAIL });
		}
	};

export const openDialogCouponAction = () => ({ type: types.OPEN_DIALOG_COUPON });

export const closeDialogCouponAction = () => ({ type: types.CLOSE_DIALOG_COUPON });

export const openDialogDiscountAction = () => ({ type: types.OPEN_DIALOG_DISCOUNT });

export const openDialogDiscountObsAction = () => ({ type: types.OPEN_DIALOG_DISCOUNT_OBS });

export const openDialogDiscountRequestAction = () => ({ type: types.OPEN_DIALOG_DISCOUNT_REQUEST });

export const closeDialogDiscountAction = () => ({ type: types.CLOSE_DIALOG_DISCOUNT });

export const couponValeListAction = () => ({ type: types.COUPON_VALE_LIST });

export const checkCouponAction =
	({ idCheckout, idCoupon, price }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.INSERT_COUPON_REQUEST });
		const { snack } = getState().main.app;

		try {
			const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
			const { calculateTax } = getState().main.checkout.geral.checkout.resume;
			const isValid = await checkCoupon({ idCoupon });

			if (isValid) {
				const resultsCp = await insertCoupon({ idCheckout, hashCupom: idCoupon, price, tipoVale: 'CP' });

				if (resultsCp.value?.isValProdMaiorQueValMaxCupom == true) {
					snack.enqueueSnackbar(resultsCp.message ? resultsCp.message : 'Cupom/Vale inserido com sucesso', { variant: 'warning' });
				} else {
					snack.enqueueSnackbar(resultsCp.message ? resultsCp.message : 'Cupom/Vale inserido com sucesso', { variant: 'success' });
				}

				dispatch({ type: types.INSERT_COUPON_SUCCESS });
				dispatch(
					getCheckoutAction({
						idCheckout,
						isSameDay: checkedSameday,
						isPickup: checkedPickup,
						entregaNitro: checkedNitrous,
						entregaAgendada: checkedSchedule,
						dataEntregaAgendada: dateSchedule,
						calculateTax,
					})
				);
			} else {
				snack.enqueueSnackbar('Cupom inválido', { variant: 'error' });
				dispatch({ type: types.INSERT_COUPON_FAIL });
			}
		} catch (e) {
			snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
			dispatch({ type: types.INSERT_COUPON_FAIL });
		}
	};

export const submitOrderAction = form => async (dispatch, getState) => {
	dispatch({ type: types.SUBMIT_ORDER_REQUEST });
	const { snack, filial } = getState().main.app;
	const formData = new FormData();

	const body = {
		...form,
		idFilial: filial.idFilial,
	};

	formData.append('body', JSON.stringify(body));
	formData.append('arquivoCompra', form.arquivoCompra);

	try {
		const { message, value } = await submitOrder(formData);

		snack.enqueueSnackbar(message, { variant: 'success' });
		dispatch({ type: types.SUBMIT_ORDER_SUCCESS });
		dispatch(setPaymentMethodAction({ paymentMethod: '' }));
    dispatch({
      type: Types.SET_CLIENT_SUCCESS,
      payload: { client: null, clients: null },
    });
    clearClient();
		setLogoutCustomer();

		const { customerId } = getState().main.checkout.geral.checkout.customer;
		removeFreight(form.checkoutId);
		replace(`/order/${value}/customer/${customerId}/PDV`);
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.SUBMIT_ORDER_FAIL });
	}
};

export const chooseFreightAction = () => ({ type: types.CHOSE_FREIGHT });
export const chooseScheduleAction = (chosedSchedule) => ({ type: types.CHOSE_SCHEDULE, payload: chosedSchedule });

export const getPdfAction = (checkoutId) => async (dispatch) => {
  dispatch({ type: types.PDF_REQUEST });
  dispatch({ type: types.OPEN_CUSTOM_LOADING, payload: {
    status: true,
    message: 'Estamos emitindo o seu relatório... aguarde um momento...'}
  });

	try {
		const data = await getPdf(checkoutId);
		const file = new Blob([data], { type: 'application/pdf' });
		window.open(URL.createObjectURL(file));
		dispatch({ type: types.PDF_SUCCESS });
		dispatch({ type: types.CLOSE_CUSTOM_LOADING });
	} catch (_e) {
		dispatch({ type: types.PDF_FAIL });
		dispatch({ type: types.CLOSE_CUSTOM_LOADING });
	}
};

export const sendPdfEmailAction = ({ checkoutId, customerId }) => async (dispatch, getState) => {
  dispatch({ type: types.PDF_REQUEST });
  dispatch({ type: types.OPEN_CUSTOM_LOADING, payload: {
    status: true,
    message: 'Estamos emitindo o seu relatório... aguarde um momento...'}
  });
  const { snack } = getState().main.app;

		try {
			const { message } = await sendEmail({ checkoutId, customerId });
			snack.enqueueSnackbar(message, { variant: 'success' });

			dispatch({ type: types.PDF_SUCCESS });
			dispatch({ type: types.CLOSE_CUSTOM_LOADING });
		} catch (e) {
			snack.enqueueSnackbar(resolveError(e), { variant: 'error' });

			dispatch({ type: types.PDF_FAIL });
			dispatch({ type: types.CLOSE_CUSTOM_LOADING });
		}
	};

export const addAllVouchersAction = body => async (dispatch, getState) => {
	dispatch({ type: types.ADD_ALL_VOUCHERS_REQUEST });
	try {
		const { snack } = getState().main.app;
		const { calculateTax } = getState().main.checkout.geral.checkout.resume;
		const { message } = await addAllVouchers(body);

		snack.enqueueSnackbar(message, { variant: 'success' });
		dispatch({ type: types.ADD_ALL_VOUCHERS_SUCCESS });
		dispatch(closeDialogCouponAction());

		const { checkout } = getState().main.checkout.geral;
		dispatch(
			getCheckoutAction({
				idCheckout: checkout.checkoutId,
				isSameDay: checkout.checkedSameday,
				isPickup: checkout.checkedPickup,
				entregaNitro: checkout.checkedNitrous,
				entregaAgendada: checkout.checkedSchedule,
				dataEntregaAgendada: checkout.dateSchedule,
				calculateTax,
			})
		);
	} catch (_e) {
		dispatch({ type: types.ADD_ALL_VOUCHERS_FAIL });
	}
};

export const tradeValueProductAction =
	({
		checkoutId,
		codProduto,
		idItemBseller,
		valorAnterior,
		valorAtribuido,
		motivoSolicitacao,
		percent,
	}) =>
	async (dispatch, getState) => {
		dispatch({ type: types.UPDATE_VALUE_ITEM_REQUEST });
		const { snack } = getState().main.app;
		const { user } = getState().auth.login;
		try {
			const { message } = await tradeValueProduct({
				checkoutId,
				codProduto,
				idItemBseller,
				valorAnterior,
				valorAtribuido,
				motivoSolicitacao,
				userSolicitacao: user.idVendedor,
				userAprovacao: user.idVendedor,
			});

			dispatch({ type: types.UPDATE_VALUE_ITEM_SUCCESS });
			snack.enqueueSnackbar(message, { variant: 'success' });
			dispatch(
				itemDiscountAction({
					productId: codProduto,
					total: valorAtribuido,
					porcentagemDesconto: percent,
				})
			);
		} catch (e) {
			dispatch({ type: types.UPDATE_VALUE_ITEM_FAILURE });
			snack.enqueueSnackbar(resolveError(e), { variant: 'success' });
		}
	};

export const showItemAction = item => ({
	type: types.SHOW_ITEM,
	payload: { item },
});

export const hideItemAction = () => ({ type: types.HIDE_ITEM });

export const showItemSearchAction = ({ item }) => ({
	type: types.SHOW_ITEM_SEARCH,
	payload: item,
});

export const hideItemSearchAction = () => ({ type: types.HIDE_ITEM_SEARCH });

export const hidePrintingAction = () => ({ type: types.HIDE_PRINTING });

export const openConfirmDialogAction = () => ({ type: types.OPEN_CONFIRM });

export const closeConfirmDialogAction = () => ({ type: types.CLOSE_CONFIRM });

export const confirmFreightAction = () => ({ type: types.CONFIRM_FREIGHT });


export const getTotalCouponsAndVouchersAction = (customerId) => async (dispatch, getState) => {
  dispatch({ type: types.GET_TOTAL_COUPONS_AND_VOUCHERS_REQUEST });
  try {
    let totalCouponsVouchers = 0;
    const data = await apiService.get(`/vales/clientes/${customerId}/saldo-total`);

    if (data.status) {
      totalCouponsVouchers = data.data.content.saldoTotal;
    }

    dispatch({
      type: types.GET_TOTAL_COUPONS_AND_VOUCHERS_SUCCESS,
      payload: { totalCouponsVouchers },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), {
      variant: 'error',
    });
    dispatch({ type: types.GET_TOTAL_COUPONS_AND_VOUCHERS_FAIL });
  }
};

export const resetSearchAction = (reset, inputValue) => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.RESET_SEARCH,
      reset,
      inputValue,
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), {
      variant: 'error',
    });
  }
};

export const searchByCategoryAction = (categoryFilter, categoryList) => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.SEARCH_BY_CATEGORY,
      categoryFilter,
      categoryList
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), {
      variant: 'error',
    });
  }
};

export const newCheckoutAction = ({checkout, product}) => async (dispatch, getState) => {
  dispatch({ type: types.NEW_CHECKOUT_REQUEST });

  try {
    const { snack } = getState().main.app;
    const client = getClient();

    const {
      data: { content, message },
    } = await apiService.post('/novo-carrinho', {
      website: "PDV",
      ...checkout,
      idCliente: client.codCliente,
      produtos: product
    });

    const checkoutId = Number(content.replace(/\s/g, '').split(':')[1]);

    setClient({
      ...client,
      checkoutId,
      confirmAddress: true,
      qtyProducts: product[0].quantity,
    });

    snack.enqueueSnackbar(`${message} - n.º ${checkoutId}`, { variant: 'success' });

    dispatch({
      type: TypesClient.SET_CLIENT_SUCCESS,
      payload: {
        client: {
          ...client,
          checkoutId,
          qtyProducts: product[0].quantity,
          confirmAddress: true,
        },
        clients: null,
      },
    });

    dispatch({ type: types.NEW_CHECKOUT_SUCCESS });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: types.NEW_CHECKOUT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
}
