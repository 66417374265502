/* istanbul ignore file */
import { createMuiTheme } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';
import { ptBR } from '@material-ui/core/locale';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
      'Source Sans Pro',
    ].join(','),
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': ['Poppins'],
        },
      },
    },
  },
  palette: {
    background: {
      paper: '#FFFFFF',
      default: '#EDEDED',
      level1: '#FFFFFF',
      level2: '#F3F4F6',
      level3: '#F5F5F5',
      level4: '#E0E0E0',
    },
    text: {
      grey1: '#707070',
    },
    primary: {
      main: '#FF5225',
    },
    secondary: blue,
    success: {
      main: '#018001',
      dark: '#005900',
      light: '#339933',
    },
    error: red,
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  shape: {
    borderRadius: 8,
  }, 
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Poppins'],
      },
    },
  },

}, ptBR);

export default theme;
