// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import { getFormValues } from 'redux-form';
import Types from './types';
import apiService from '../../../services/apiService';
import { getClient } from '../../../services/authClient';
import { getFilialVendedor } from '../../../services/app';
import { getUser } from '../../../services/auth';

const getAnswersChatAction = ({
  productId: idProduto,
  userId: idUsuario,
}, args = {}) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ANSWERS_CHAT_REQUEST });
  try {
    const { page, limit } = getState().intelligence;
    const client = getClient();
    const idFilial = getFilialVendedor();

    const { data } = await apiService.get(
      `/faq-ia/produtos/${idProduto}/usuario/${idUsuario}`, {
        params: {
          checkoutId: client && client.checkoutId,
          page: args.page || page,
          limit: args.limit || limit,
          idFilialVendedor: idFilial,
        },
      },
    );

    dispatch({ type: Types.GET_ANSWERS_CHAT_SUCCESS, answers: data });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ANSWERS_CHAT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const sendQuestionToChatAction = ({
  productId: idProduto,
  userId: idUsuario,
}) => async (dispatch, getState) => {
  dispatch({ type: Types.SEND_QUESTION_TO_CHAT_REQUEST });
  try {
    const { question: pergunta } = getFormValues('questionsAndAnswers/chatAI')(getState());
    const { answers, answer } = getState().intelligence;
    const client = getClient();
    const idFilial = getFilialVendedor();

    const { data } = await apiService.post('/faq-ia/perguntas', {
      idUsuario,
      idProduto,
      pergunta,
      checkoutId: client && client.checkoutId,
      idFilialVendedor: idFilial,
    });

    dispatch({
      type: Types.SEND_QUESTION_TO_CHAT_SUCCESS,
      answer: Object.keys(data).length ? data : null,
      answers: answer ? [...answers, answer] : answers,
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.SEND_QUESTION_TO_CHAT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const sendLikeToChatAction = ({
  questionId: id,
  relevant: relevante,
  productId: idProduto,
}) => async (dispatch, getState) => {
  dispatch({ type: Types.SEND_LIKE_TO_CHAT_REQUEST });
  try {
    const client = getClient();
    const user = getUser();
    const idFilial = getFilialVendedor();
    const { answer } = getState().intelligence;

    await apiService.put(`/faq-ia/perguntas/${id}`, {
      relevante,
      idProduto,
      checkoutId: client && client.checkoutId,
      idFilialVendedor: idFilial,
      pergunta: answer.pergunta,
      resposta: answer.resposta,
      idUsuario: user && user.idVendedor,
    });

    dispatch({ type: Types.SEND_LIKE_TO_CHAT_SUCCESS, isRelevant: relevante });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.SEND_LIKE_TO_CHAT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getAnswersChatAction,
  sendQuestionToChatAction,
  sendLikeToChatAction,
};
