import { handleActions } from 'redux-actions';
import types from './types';

const INITIAL_STATE = {
  typePayment: null,
  saving: false,
  dialogOpened: false,
  dialogPixOpened: false,
  dialogPsOpened: false,
  loadingInstallmets: false,
  loadingPix: false,
  installments: [],
  checkPix: false,
  paymentPixName: '',
  pixQrCode: {},
  pixEmail: {},
  pixSms: {},
  pixWhats: {},
};

const detail = handleActions({
  [types.OPEN_DIALOG]: (state) => ({
    ...state,
    dialogOpened: true,
    installments: [],
  }),
  [types.CLOSE_DIALOG]: (state) => ({
    ...state,
    dialogOpened: false,
    typePayment: null,
  }),
  [types.OPEN_PIX_DIALOG]: (state) => ({
    ...state,
    dialogPixOpened: true,
    installments: [],
  }),
  [types.OPEN_PS_DIALOG]: (state) => ({
    ...state,
    dialogPsOpened: true,
  }),
  [types.CLOSE_PIX_DIALOG]: (state) => ({
    ...state,
    dialogPixOpened: false,
    typePayment: null,
  }),
  [types.CLOSE_PS_DIALOG]: (state) => ({
    ...state,
    dialogPsOpened: false,
  }),
  [types.CANCEL_PAYMENT]: (state) => ({
    ...state,
    typePayment: null,
  }),
  [types.UPDATE_PAYMENT_TYPE]: (state, { payload }) => ({
    ...state,
    typePayment: payload.type,
  }),
  [types.GET_INSTALLMENTS]: (state) => ({
    ...state,
    loadingInstallmets: true,
  }),
  [types.GET_INSTALLMENTS_FAIL]: (state) => ({
    ...state,
    loadingInstallmets: false,
  }),
  [types.GET_INSTALLMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loadingInstallmets: false,
    installments: payload.installments,
  }),
  [types.GET_PIX_REQUEST]: (state) => ({
    ...state,
  }),
  [types.GET_PIX_FAIL]: (state) => ({
    ...state,
  }),
  [types.GET_PIX_SUCCESS]: (state, { payload }) => ({
    ...state,
    pixQrCode: payload,
  }),
  [types.GET_CHECK_PIX_REQUEST]: (state) => ({
    ...state,
  }),
  [types.REMOVE_PIX_REQUEST]: (state) => ({
    ...state,
    loadingPix: true,
  }),
  [types.REMOVE_PIX_SUCCESS]: (state) => ({
    ...state,
    loadingPix: false,
  }),
  [types.REMOVE_PIX_FAIL]: (state) => ({
    ...state,
    loadingPix: false,
  }),
  [types.GET_CHECK_PIX_FAIL]: (state) => ({
    ...state,
  }),
  [types.GET_CHECK_PIX_SUCCESS]: (state, { payload }) => ({
    ...state,
    checkPix: payload,
  }),
  [types.POST_PIX_SMS_REQUEST]: (state) => ({
    ...state,
    loadingPix: true,
  }),
  [types.POST_PIX_SMS_FAIL]: (state) => ({
    ...state,
    loadingPix: false,
  }),
  [types.POST_PIX_SMS_SUCCESS]: (state, { payload }) => ({
    ...state,
    pixSms: payload,
    loadingPix: false,
  }),
  [types.POST_PIX_WHATS_REQUEST]: (state) => ({
    ...state,
    loadingPix: true,
  }),
  [types.POST_PIX_WHATS_FAIL]: (state) => ({
    ...state,
    loadingPix: false,
  }),
  [types.POST_PIX_WHATS_SUCCESS]: (state, { payload }) => ({
    ...state,
    pixWhats: payload,
    loadingPix: false,
  }),
  [types.POST_PIX_EMAIL_REQUEST]: (state) => ({
    ...state,
    loadingPix: true,
  }),
  [types.POST_PIX_EMAIL_FAIL]: (state) => ({
    ...state,
    loadingPix: false,
  }),
  [types.POST_PIX_EMAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    pixEmail: payload,
    loadingPix: false,
  }),
}, INITIAL_STATE);

export default detail;
