import { useEffect, useState } from 'react';

export const useCount = (initialCount = 0) => {
  const [counting, setCounting] = useState(initialCount);

  useEffect(() => {
    if (counting > 3) {
      setCounting(1);
      return;
    };

    const interval = setInterval(() => {
      setCounting(counting + 1);
    }, 250);

    return () => clearInterval(interval);
  }, [counting]);

  return {
    counting,
  };
}

export default useCount;