// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Types from './types';
import apiService from '../../../shared/services/apiService';

const getClientByDocumentAction = (cnpj) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_CLIENT_BY_DOCUMENT_REQUEST });
  try {
    const { snack } = getState().main.app;
    const {
      data: {
        content,
        message,
      },
    } = await apiService.get(`/clientes/consulta-dados-pj/${cnpj}`);

    const semNumero = content.endereco.numero === 'S N';

    const client = {
      cnpj: content.cnpj,
      razaoSocial: content.razaoSocial,
      nomeComprador: content.nomeFantasia,
      ie: content.inscricaoEstadual,
      cep: content.endereco.cep,
      logradouro: content.endereco.logradouro,
      numero: !semNumero ? content.endereco.numero : null,
      cidade: content.endereco.cidade,
      estado: content.endereco.uf,
      bairro: content.endereco.bairro,
      tipoContribuinte: content.tipoContribuinte,
      semNumero,
    };

    dispatch({ type: Types.GET_CLIENT_BY_DOCUMENT_SUCCESS });
    snack.enqueueSnackbar(message, { variant: 'success' });
    return client;
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_CLIENT_BY_DOCUMENT_FAILURE });
    snack.enqueueSnackbar((e.response.data.content ? e.response.data.content.message : e.message), { variant: 'error' });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getClientByDocumentAction,
};
