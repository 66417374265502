import React from 'react';
import {
  Box, Button, FormControlLabel, Grid, Switch, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from './styles';
import { toCEP, toCNPJ, toPhone } from '../../../../../../shared/utils/parsers';
import { push } from '../../../../../../history';
import {
  getAddressesClientPFAction, getAddressesClientPJAction,
  handleInactiveAddressPFAction, handleInactiveAddressPJAction,
  putMainAddressPFAction, putMainAddressPJAction,
} from '../../actions';

const AddressesList = ({
  client,
  addresses,
  putMainAddressPF,
  getAddressesClientPF,
  handleInactiveAddressPF,
  putMainAddressPJ,
  getAddressesClientPJ,
  handleInactiveAddressPJ,
}) => {
  const isCorporate = client.tipoPessoa === 'J';

  const {
    addressGrid,
    addressBox,
    headerBox,
    addressMain,
    activeInactiveBtn,
    editBtn,
    activeInactiveForm,
    infoClientBox,
    mainSelectBtn,
  } = useStyles();

  const redirectEditAddress = (addressId) => push(`/client-space/address/edit/${addressId}`);

  const selectMainAddress = async (id, address) => {
    if (isCorporate) {
      await putMainAddressPJ(id, address);
      await getAddressesClientPJ(client.codClienteMatriz || client.codCliente);
    } else {
      await putMainAddressPF(id, address);
      await getAddressesClientPF(client.codCliente);
    }
  };

  const updateInaciveAddress = async (id) => {
    if (isCorporate) {
      await handleInactiveAddressPJ(id);
      await getAddressesClientPJ(client.codClienteMatriz || client.codCliente);
    } else {
      await handleInactiveAddressPF(id);
      await getAddressesClientPF(client.codCliente);
    }
  };

  return (
    <Grid className={addressGrid}>
      {
        addresses && addresses.map((address) => (
          <Box
            key={address.logradouro}
            className={addressBox}
          >
            <Box className={headerBox}>
              {
                address.bitPrincipal ? (
                  <span className="main">
                    Endereço Principal
                  </span>
                ) : (
                  <Button
                    classes={{ root: mainSelectBtn }}
                    onClick={() => (address.bitInativo ? {} : (
                      selectMainAddress((isCorporate ? address.codCliente : address.id), address)
                    ))}
                    disabled={!!address.bitInativo}
                  >
                    <span className={`main select ${address.bitInativo && 'inactive'}`}>
                      Definir como Principal
                    </span>
                  </Button>
                )
              }
              <Button
                classes={{ root: editBtn }}
                onClick={() => redirectEditAddress(isCorporate ? address.codCliente : address.id)}
              />
            </Box>
            <span className={`${addressMain} ${!!address.bitInativo && ('inactive')}`}>{`${address.logradouro}, ${address.numero}`}</span>
            <span>{`${address.bairro} - ${address.cidade}/${address.uf || address.estado} - CEP ${toCEP(address.cep)}`}</span>
            {
              isCorporate && !!address.cnpj ? (
                <>
                  <span>
                    {`${address.razaoSocial} - ${toPhone(address.telPrincipal)}`}
                  </span>
                  <Box className={infoClientBox}>
                    <span>
                      {`CNPJ - ${toCNPJ(address.cnpj)}`}
                    </span>
                    <Tooltip title={`${
                      address.email 
                        ? 'Não é possível desativar endereço da matriz'
                        : address.bitInativo
                          ? 'Ativar Endereço'
                          : 'Desativar Endereço'
                        }
                      `}
                      arrow
                    >
                      <FormControlLabel
                        classes={{ root: activeInactiveForm }}
                        disabled={address.email}
                        control={(
                          <Switch
                            classes={{ root: activeInactiveBtn }}
                            checked={!address.bitInativo}
                            onClick={() => updateInaciveAddress(address.codCliente)}
                          />
                        )}
                      />
                    </Tooltip>
                  </Box>
                </>
              ) : (
                <Box className={infoClientBox}>
                  <span>
                    {`${isCorporate ? (
                      `${address.razaoSocial} - ${toPhone(address.telPrincipal)}`
                    ) : (
                      `${address.responsavel || address.nome} - ${toPhone(address.telefoneContato)}`
                    )}`}
                  </span>
                  <Tooltip title={`${address.bitInativo ? 'Ativar' : 'Desativar'} Endereço`} arrow>
                    <FormControlLabel
                      classes={{ root: activeInactiveForm }}
                      control={(
                        <Switch
                          classes={{ root: activeInactiveBtn }}
                          checked={!address.bitInativo}
                          onClick={() => updateInaciveAddress(address.id)}
                        />
                      )}
                    />
                  </Tooltip>
                </Box>
              )
            }
          </Box>
        ))
      }
    </Grid>
  );
};

AddressesList.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  addresses: PropTypes.instanceOf(Array).isRequired,
  putMainAddressPF: PropTypes.func.isRequired,
  getAddressesClientPF: PropTypes.func.isRequired,
  handleInactiveAddressPF: PropTypes.func.isRequired,
  putMainAddressPJ: PropTypes.func.isRequired,
  getAddressesClientPJ: PropTypes.func.isRequired,
  handleInactiveAddressPJ: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const { client } = state.authClient;
  const {
    addresses,
  } = state.main.clientAddresses;

  return {
    client,
    addresses,
  };
};

export default connect(mapState, {
  putMainAddressPF: putMainAddressPFAction,
  getAddressesClientPF: getAddressesClientPFAction,
  handleInactiveAddressPF: handleInactiveAddressPFAction,
  putMainAddressPJ: putMainAddressPJAction,
  getAddressesClientPJ: getAddressesClientPJAction,
  handleInactiveAddressPJ: handleInactiveAddressPJAction,
})(AddressesList);
