import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  editFormGrid: {
    '& textarea': {
      height: '214px !important',
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },

    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },

    '& [class*=fieldContent]': {
      marginBottom: 6,

      '& label': {
        color: '#7B7F82',
      },
    },

    '& [class*=alertShipping]': {
      marginBottom: 0,
    },
  },
  addressGrid: {
    display: 'flex',
    gap: 8,

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  numberGrid: {
    padding: '0 8px',
  },
  noNumberGrid: {
    marginTop: 28,
  },
  numberMainGrid: {
    display: 'flex',
  },
  btnBox: {
    marginTop: '12px !important',
    display: 'flex',
    gap: 12,
    justifyContent: 'end',

    '& button': {
      height: 48,
      textTransform: 'capitalize',
      fontWeight: 600,
      borderRadius: 8,

      '&:last-child': {
        minWidth: 232,
      },
    },

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  cepInfoGrid: {
    display: 'flex',
    alignItems: 'center',

    '& button': {
      fontSize: 12,
      lineHeight: '18px',
      textTransform: 'math-auto',
      color: '#7B7F82',
    },

    [breakpoints.up('sm')]: {
      marginTop: 20,
    },
  },
  info: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#7B7F82',
    fontWeight: 500,
  },
  corporateAddressGrid: {
    width: '100%',
    display: 'flex',
    gap: 16,

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  suframaMainGrid: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
  },
  suframaGrid: {
    margin: 0,
  },
}));

export default useStyles;
