import { handleActions, combineActions } from 'redux-actions';
import Types from './types';

const INITIAL_STATE = {
  loading: {
    cnpj: false,
  },
};

const stopLoading = combineActions(
  Types.GET_CLIENT_BY_DOCUMENT_SUCCESS,
  Types.GET_CLIENT_BY_DOCUMENT_FAILURE,
);

export default handleActions({
  [stopLoading]: () => ({
    loading: {
      cnpj: false,
    },
  }),
  [Types.GET_CLIENT_BY_DOCUMENT_REQUEST]: () => {
    return ({
      loading: {
        cnpj: true,
      },
    });
  },
}, INITIAL_STATE);
