import React, { useEffect, useState, useRef } from 'react';
import {connect, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ArrowBack, CloseRounded, Print } from '@material-ui/icons';
import CustomButtom from '../../../shared/components/customButton';

import { useMediaQuery, useTheme, Divider, Button, Box, ButtonGroup, Tooltip, Paper } from '@material-ui/core';

import CardBar from '../../../shared/components/cardBar';
import useStyles from './styles';
import {
	getOrderAction,
	getCustomerAction,
	resetOrderAction,
	printBilletAction,
	getPdfAction,
	sendPdfEmailAction,
	getDetailedDeliveryAction,
	resetDetailedDeliveryAction,
	getLinkFileAction,
	submitFileAction,
	getDetailsFromCaixaAction,
	getReimpressaoCupomFiscalAction,
} from './actions';
import { CustomerInfo, AddressInfo, AdditionalInfo, ResumeOrderInfo } from '../../../shared/components/info';

import { OrderDetails, OrderResume, OrderDetailsModal } from '../../../shared/components/orderDetails';
import LuckyForThoseWhoDo from '../../../shared/components/campaign/LuckyForThoseWhoDo';
import AlertTitleLdm from '../../../shared/components/alert';
import OrderDetailsCaixa from '../../../shared/components/orderDetails/OrderDetailsCaixa';
import CircularLoading from '../../../shared/components/loading/CircularLoading';
import OrderResumeTesouraria from '../../../shared/components/orderDetails/OrderResumeTesouraria';
import { goBack } from '../../../history';
import {Types} from "../../authClient/login";
import {clearClient} from "../../../shared/services/authClient";
import {setLogoutCustomer} from "../../../shared/services/app";

const DetailPage = ({
	order,
	customer,
	linkFile,
	getLinkFile,
	submitFile,
	getOrder,
	getCustomer,
	loadings,
	resetOrder,
	printBillet,
	getPdf,
	sendPdfEmail,
	getDetailedDelivery,
	resetDetailedDelivery,
	getDetailsFromCaixa,
	getReimpressaoCupomFiscal,
	detailed,
	user,
	match: {
		params: { orderId, customerId, type },
	},
}) => {
  const dispatch = useDispatch();
	const [pagamentos, setPagamentos] = useState([]);
	const ref = useRef();

	let BP;
	pagamentos.forEach(payment => {
		if (payment.type === 'BP') {
			BP = true;
			return BP;
		}
		return BP;
	});

	const { breakpoints } = useTheme();
	const isCardBarLeft = useMediaQuery(breakpoints.up('sm'));
	const classes = useStyles();
	const loading = loadings.order || loadings.checkout || loadings.detailed;
	const [nameFile, setNameFile] = useState('');
	const [kbFile, setKbFile] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [errorFile, setErrorFile] = useState(false);

	const handleFileChange = e => {
		if (e.target.files[0].size >= 5000000) {
			setErrorFile(true);
			ref.current.value = '';
			setSelectedFile('');
			setNameFile('');
			setKbFile('');
		} else {
			setErrorFile(false);
			setSelectedFile(e.target.files[0]);
			setNameFile(`${e.target.files[0].name.substring(0, 19)}...`);
			setKbFile(`(${Math.round(e.target.files[0].size / 100 / 10)} kB)`);
		}
	};

	const handleRemoveFile = () => {
		ref.current.value = '';
		setSelectedFile('');
		setNameFile('');
		setKbFile('');
	};

	const submitFileOc = async () => {
		await submitFile(orderId, selectedFile);
		setTimeout(getLinkFile(orderId), 4000);
		ref.current.value = '';
		setSelectedFile('');
		setNameFile('');
		setKbFile('');
	};

	const [modal, setModal] = useState(false);

	const handleDetailedDelivery = deliveryId => {
		const orderDate = moment(order.dataHoraPedido, 'DD/MM/YYYY hh:mm:ss').format('DD-MM-YYYY');

		getDetailedDelivery({ deliveryId, orderDate });
	};

	const handleCloseDetailedModal = () => {
		setModal(false);
		resetDetailedDelivery();
	};

	const handleBack = () => goBack();
	const handlePrintNFCE = () => getReimpressaoCupomFiscal(orderId);

	useEffect(() => {
		if (order) {
			setPagamentos(order.pagamentos);
		}
	}, [order]);

	useEffect(() => {
		if (detailed && detailed.length > 0) {
			setModal(true);
		}
	}, [detailed]);

	useEffect(() => {
		getLinkFile(orderId);
	}, [orderId, customer]);

	useEffect(() => {
		resetOrder();

		if (type === 'CAIXA') {
      getDetailsFromCaixa(orderId).catch((_) => {
        getOrder(orderId, customerId);
      });
    } else {
      getOrder(orderId, customerId);
    }

    getCustomer(customerId);
	}, [getOrder, customerId, getCustomer, getDetailsFromCaixa, resetOrder]);

  useEffect(() => {
    dispatch({
      type: Types.SET_CLIENT_SUCCESS,
      payload: { client: null, clients: null },
    });
    clearClient();
    setLogoutCustomer();
  }, []);

	return (
		<>
			{detailed && (
				<OrderDetailsModal detailed={detailed} open={modal} onClose={handleCloseDetailedModal} />
			)}
			<CircularLoading open={loading} />

			<section className={classes.root}>
				<main className={classes.itemsContainer}>
					{order && order.bitTesouraria ? (
						<div className={classes.containerCaixa}>
							<Paper disableElevation className={classes.paperCaixa}>
								<OrderDetailsCaixa delivery={order} orderId={+orderId} index={0} detailedDelivery={handleDetailedDelivery} />
							</Paper>

							<Paper disableElevation className={classes.paperCaixa}>
								<OrderResumeTesouraria
									pedido={order}
									pagamentos={order.pagamentos}
								/>
							</Paper>

							<Box display="flex" justifyContent="end" sx={{gap: 12}}>
								<Button className={classes.buttonActions} onClick={handleBack} color="default" variant="outlined">
									<ArrowBack/> Voltar
								</Button>
								<Button className={classes.buttonActions} onClick={handlePrintNFCE} color="default" variant="outlined">
									<Print/> Cupom fiscal (NFCE)
								</Button>
							</Box>
						</div>
					) : order && !order.bitTesouraria && (
						<>
							{isCardBarLeft && <CardBar title={`Pedido ${order ? `#${orderId}` : ''}`} forcePath="/checkouts-orders" />}
							{order &&
								order.entregas.map((delivery, index) => (
									<OrderDetails order={order} delivery={delivery} detailedDelivery={handleDetailedDelivery} key={`detail-${index + 1}`} />
								)
							)}

							{/* pedido cancelado e Inconscistencia no cadastro */}
							{order && order.motivoCancelamento && order.motivoCancelamento.trim() != '' && (
								<AlertTitleLdm title="PEDIDO CANCELADO" text={order.motivoCancelamento} />
							)}

							{order && order.msgPainelDeInconsistencias && order.msgPainelDeInconsistencias.trim() != '' && (
								<AlertTitleLdm title="PEDIDO NO PAINEL DE INCONSISTÊNCIAS" text={order.msgPainelDeInconsistencias} />
							)}

							<OrderResume
								pagamentos={order.pagamentos}
								enderecoEntrega={order.enderecoEntrega}
								meta={order.meta}
								desconto={order.desconto}
							/>

							{/* Campanha Sorte de Quem Faz */}
							{user.bitCampanhaAtiva && order && order.quantidadeNumerosDaSorte > 0 && (
								<LuckyForThoseWhoDo luckyNumbers={order.quantidadeNumerosDaSorte} />
							)}
						</>
					)}
				</main>

				{order && !order.bitTesouraria && (
					<aside className={classes.detailContainer}>
						{!isCardBarLeft && <CardBar title={`Pedido ${order ? `#${orderId}` : ''}`} />}
						{order && customer && (
							<>
								<CustomerInfo
									name={customer.name}
									socialName={order.enderecoEntrega.nomeSocial}
									document={customer.document}
									typePerson={customer.typePerson}
									phone={customer.phone}
									email={customer.email}
									nomeComprador={customer.nomeComprador}
								/>

								<AddressInfo
									address={order.enderecoEntrega}
									deliveryDate={order && order.entregas[0].itens[0] ? order.entregas[0].itens[0].prazoEntrega : 'SEM PRAZO DE ENTREGA'}
									state={customer.address?.state}
									number={customer.address?.number}
									addInfo={customer.address?.addInfo}
									complement={
										customer.address?.complement != 'null' && customer.address?.complement != null ? customer.address?.complement : ''
									}
									city={customer.address?.city}
									street={customer.address?.street}
									zipcode={customer.address?.zipCode}
								/>

								<Divider />

								<div style={{ width: '90%', margin: 'auto', marginTop: 8, marginBottom: 8 }}>
									<AdditionalInfo
										readOnly
										loading={loading}
										isPickup={false}
										typePerson={customer.typePerson}
										initialValues={{
											...order.informacoesAdicionais,
											documentoNF: order.informacoesAdicionais.documentoNF || customer.document,
										}}
									/>
								</div>

								<ResumeOrderInfo
									productsCount={order.meta.productsCount}
									subTotalPrazo={order.meta.subTotal}
									subTotalVista={order.subTotalVista}
									shipping={order.meta.shipping}
									voucher={order.meta.voucher}
									discount={order.meta.discount}
									maxDiscount={order.meta.discount}
									total={order.meta.total}
									totalGarantiaEstendida={order.pagamentos[0]?.garantiaEstendida}
									pontos={order.pontos}
									readOnly
								/>

								<Divider />

								<div style={{ display: 'flex', flexDirection: 'column', padding: '16px', rowGap: '8px', margin: '8px 0px' }}>
									{linkFile &&
										linkFile.map((link, i) => (
											<Tooltip title={`Ordem de compra ${i + 1}`} key={i}>
												<a
													href={link.fileUrlDownloadOc}
													target="_blank"
													style={{
														textDecoration: 'none',
														textAlign: 'center',
														padding: '5px 5px',
														border: '1px solid #FA4616',
														color: '#FA4616',
														height: '30px',
														borderRadius: '4px',
													}}
												>
													Download OC {i + 1}
												</a>
											</Tooltip>
										))}
								</div>
								{customer?.typePerson == 'J' && BP && (
									<>
										<Box p={2} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', rowGap: '8px' }}>
											<>
												<div>Nova Ordem de Compra</div>
												<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '4px' }}>
													<div className={classes.fileUpload}>
														<p
															style={{
																color: '#2296F3',
																fontSize: '14px',
																display: 'flex',
															}}
														>
															{!nameFile && !kbFile ? (
																<div style={{ display: 'flex', columnGap: '4px', alignItems: 'center' }}>
																	Anexar arquivo
																	<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M10.3337 11.834V8.50065H12.8337L9.50033 4.33398L6.16699 8.50065H8.66699V11.834H10.3337Z"
																			fill="#2296F3"
																		/>
																		<path
																			d="M5.33366 11.8337H7.00033V10.167H5.33366C3.95533 10.167 2.83366 9.04533 2.83366 7.66699C2.83366 6.49699 3.83283 5.37033 5.06116 5.15449L5.54533 5.06949L5.70533 4.60449C6.29116 2.89533 7.74616 1.83366 9.50033 1.83366C11.7978 1.83366 13.667 3.70283 13.667 6.00033V6.83366H14.5003C15.4195 6.83366 16.167 7.58116 16.167 8.50033C16.167 9.41949 15.4195 10.167 14.5003 10.167H12.0003V11.8337H14.5003C16.3387 11.8337 17.8337 10.3387 17.8337 8.50033C17.8324 7.75333 17.5809 7.02831 17.1192 6.44102C16.6576 5.85373 16.0125 5.43804 15.287 5.26033C14.9228 2.39199 12.467 0.166992 9.50033 0.166992C7.20366 0.166992 5.20866 1.50949 4.29783 3.62533C2.50783 4.16033 1.16699 5.85033 1.16699 7.66699C1.16699 9.96449 3.03616 11.8337 5.33366 11.8337Z"
																			fill="#2296F3"
																		/>
																	</svg>
																</div>
															) : (
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		columnGap: '2px',
																	}}
																>
																	<div>{nameFile}</div>
																	<div style={{ fontSize: '10px', color: '#242424', fontWeight: '500' }}>{kbFile}</div>
																</div>
															)}
														</p>
														<input
															className={classes.upload}
															type="file"
															onChange={handleFileChange}
															id="file_input_file"
															ref={ref}
															accept="application/msword, application/vnd.ms-powerpoint,
										text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
														/>
													</div>
													{nameFile && kbFile && <CloseRounded style={{ height: '15px', width: '15px' }} onClick={handleRemoveFile} />}
													{errorFile && (
														<div
															style={{
																color: 'red',
																fontSize: '11px',
																textAlign: 'center',
																marginTop: '4px',
															}}
														>
															Ops! Tamanho máximo 5Mb.
														</div>
													)}
												</div>
												<CustomButtom color={'#4CAF50'} onClick={submitFileOc} disabled={!selectedFile} style={{ height: '30px' }}>
													ENVIAR ARQUIVO
												</CustomButtom>
											</>
										</Box>
										<Divider />
									</>
								)}

								<Box p={2}>
									<ButtonGroup color="secondary" fullWidth>
										<Tooltip title="Imprimir detalhes do pedido">
											<Button onClick={() => getPdf(orderId)}>Imprimir</Button>
										</Tooltip>

										<Tooltip title="Enviar detalhes do pedido no e-mail">
											<Button onClick={() => sendPdfEmail({ orderId, customerId })}>Enviar</Button>
										</Tooltip>
									</ButtonGroup>
								</Box>

								{order.meta.hasBillet && (
									<Box pr={2} pl={2}>
										<Button fullWidth color="primary" variant="contained" onClick={() => printBillet(orderId)} disableElevation>
											Imprimir Boleto
										</Button>
									</Box>
								)}
								{order?.url && (
									<Box pr={2} pl={2} style={{ marginTop: '10px' }}>
										<a
											href={order.url}
											target="_blank"
											style={{
												background: 'rgb(76, 175, 80)',
												color: '#FFFFFF',
												display: 'flex',
												columnGap: '8px',
												justifyContent: 'center',
												alignItems: 'center',
												textDecoration: 'none',
												padding: '8px 16px',
												borderRadius: '4px',
												fontWeight: '500',
											}}
										>
											CONFIRMAR TOKEN
											<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M3.53101 7V6.125C3.53101 4.73261 4.08413 3.39726 5.0687 2.41269C6.05326 1.42812 7.38862 0.875 8.78101 0.875C10.1734 0.875 11.5088 1.42812 12.4933 2.41269C13.4779 3.39726 14.031 4.73261 14.031 6.125V7H15.781C16.0131 7 16.2356 7.09219 16.3997 7.25628C16.5638 7.42038 16.656 7.64294 16.656 7.875V18.375C16.656 18.6071 16.5638 18.8296 16.3997 18.9937C16.2356 19.1578 16.0131 19.25 15.781 19.25H1.78101C1.54894 19.25 1.32638 19.1578 1.16229 18.9937C0.998193 18.8296 0.906006 18.6071 0.906006 18.375V7.875C0.906006 7.64294 0.998193 7.42038 1.16229 7.25628C1.32638 7.09219 1.54894 7 1.78101 7H3.53101ZM14.906 8.75H2.65601V17.5H14.906V8.75ZM7.90601 13.7655C7.57238 13.5729 7.31164 13.2756 7.16422 12.9197C7.01679 12.5637 6.99093 12.1691 7.09064 11.797C7.19034 11.4249 7.41005 11.0961 7.71567 10.8616C8.0213 10.6271 8.39577 10.5 8.78101 10.5C9.16624 10.5 9.54071 10.6271 9.84634 10.8616C10.152 11.0961 10.3717 11.4249 10.4714 11.797C10.5711 12.1691 10.5452 12.5637 10.3978 12.9197C10.2504 13.2756 9.98963 13.5729 9.65601 13.7655V15.75H7.90601V13.7655ZM5.28101 7H12.281V6.125C12.281 5.19674 11.9123 4.3065 11.2559 3.65013C10.5995 2.99375 9.70926 2.625 8.78101 2.625C7.85275 2.625 6.96251 2.99375 6.30613 3.65013C5.64975 4.3065 5.28101 5.19674 5.28101 6.125V7Z"
													fill="#FFFFFF"
												></path>
											</svg>
										</a>
									</Box>
								)}
							</>
						)}
					</aside>
				)}
			</section>
		</>
	);
};

DetailPage.defaultProps = {
	order: null,
	customer: null,
	linkFile: null,
};

DetailPage.propTypes = {
	order: PropTypes.instanceOf(Object),
	linkFile: PropTypes.instanceOf(Object),
	customer: PropTypes.instanceOf(Object),
	match: PropTypes.instanceOf(Object).isRequired,
	getOrder: PropTypes.func.isRequired,
	getLinkFile: PropTypes.func.isRequired,
	submitFile: PropTypes.func.isRequired,
	getCustomer: PropTypes.func.isRequired,
	resetOrder: PropTypes.func.isRequired,
	printBillet: PropTypes.func.isRequired,
	getPdf: PropTypes.func.isRequired,
	sendPdfEmail: PropTypes.func.isRequired,
	getDetailedDelivery: PropTypes.func.isRequired,
	resetDetailedDelivery: PropTypes.func.isRequired,
	user: PropTypes.instanceOf(Object).isRequired,
	loadings: PropTypes.shape({
		order: PropTypes.bool,
		customer: PropTypes.bool,
		detailed: PropTypes.bool,
	}).isRequired,
};

const mapState = state => ({
	...state.main.order.detail,
	user: state.auth.login.user,
});

export default connect(mapState, {
	getOrder: getOrderAction,
	getCustomer: getCustomerAction,
	getDetailsFromCaixa: getDetailsFromCaixaAction,
	getReimpressaoCupomFiscal: getReimpressaoCupomFiscalAction,
	resetOrder: resetOrderAction,
	printBillet: printBilletAction,
	getPdf: getPdfAction,
	sendPdfEmail: sendPdfEmailAction,
	getDetailedDelivery: getDetailedDeliveryAction,
	resetDetailedDelivery: resetDetailedDeliveryAction,
	getLinkFile: getLinkFileAction,
	submitFile: submitFileAction,
})(DetailPage);
