import { makeStyles } from '@material-ui/core';
import { GuaranteeIcon, ProductManagementIcon } from '../../../../assets';
import { BuildingIcon, StoreFilialIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ palette, breakpoints, transitions }) => ({
  wrapper: {
    borderBottom: '1px solid rgba(0,0,0,.12)',
    padding: 0,
    paddingBottom: 0,
    position: 'relative',
    [breakpoints.only('md')]: {
      paddingTop: ({ drawerIsOpened }) => (drawerIsOpened ? 16 : 32),
    },
    [breakpoints.up('lg')]: {
      paddingTop: 10,
    },
  },
  productBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'left',
    },
    '& p': {
      fontWeight: 600,
    },
    '& span': {
      color: palette.text.grey1,
    },
  },
  productPrices: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: 16,
    [breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingLeft: 0,
    },
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  pricesContainer: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      flexDirection: ({ drawerIsOpened }) => (drawerIsOpened ? 'row' : 'column'),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'row !important',
    },
  },
  groupPrice: {
    minWidth: 116,
    [breakpoints.down('xs')]: {
      width: '100%',
      padding: '8px 0',
    },
  },
  titleTypePayment: {
    fontSize: 12,
    color: palette.primary.main,
  },
  oldPrice: {
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  currentPrice: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    [breakpoints.down('sm')]: {
      padding: 7,
      textAlign: 'left',
    },
  },
  input: {
    width: 116,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputDiscount: {
    width: 82,
    [breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
    },
    '& .MuiOutlinedInput-root ': {
      borderRadius: '4px 0px 0px 4px',
    },
  },
  discountButton: {
    borderRadius: '0px 4px 4px 0px',
    borderLeft: 'none',
    padding: 7,
    minWidth: 32,
    width: 32,
  },
  inputQtd: {
    width: 95,
    minWidth: 80,
    [breakpoints.down('md')]: {
      width: ({ drawerIsOpened }) => (drawerIsOpened ? 116 : 80),
      minWidth: ({ drawerIsOpened }) => (drawerIsOpened ? 116 : 80),
    },
    [breakpoints.down('sm')]: {
      minWidth: '116px !important',
      width: '30% !important',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  infoContainer: {
    [breakpoints.down('sm')]: {
      width: 82,
    },
  },
  companyName: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  warningIcon: {
    color: palette.error.main,
  },
  model: {
    color: '#707070',
    fontWeight: 300,
    fontSize: 11,
  },
  model2: {
    color: '#707070',
    fontWeight: 300,
    fontSize: 11,
    whiteSpace: 'nowrap',
  },
  stock: {
    height: 28,
    width: 28,
    fontSize: 16,
  },
  dialogTitle: {
    '& .MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  },
  stockTitle: {
    color: palette.primary.main,
    padding: '6px 0',

    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 700,
    },

    '& span': {
      textTransform: 'uppercase',
    },
  },
  stockGrid: {
    marginBottom: 30,
  },
  stockImage: {
    width: 32,
    height: 32,
    borderRight: '1px solid #E3E3E7',
    backgroundColor: '#F6F6F7 !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    background: `url(${StoreFilialIcon})`,

    '&.main': {
      background: `url(${BuildingIcon})`,
    },
  },
  stockIcon: {
    width: 52,
    height: 52,
    backgroundColor: '#F6F6F7 !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    background: `url(${ProductManagementIcon})`,
  },
  stockHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F6F6F7',
    border: '1px solid #E3E3E7',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 700,
    padding: '4px 10px',

    '& span:first-of-type': {
      textTransform: 'uppercase',
    },
  },
  stockBody: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #E3E3E7',
    borderTop: 0,

    '& [class*=MuiBox]': {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& span:last-of-type': {
        textTransform: 'uppercase',
      },
    },

  },
  productId: {
    '& span': {
      height: 8,
      color: palette.primary.main,
      fontSize: 11,
      textTransform: 'none',
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
  },
  productVirtual: {
    '& span': {
      height: 8,
      color: '#0075FF',
      fontSize: 10,
      textTransform: 'none',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
  },
  btnNitro: {
    '& span': {
      height: 8,
      color: '#2296f3',
      fontSize: 11,
      textTransform: 'none',
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
  },
  pma: {
    color: palette.primary.main,
    fontWeight: 600,
  },
  expandIcon: {
    transition: transitions.create('transform', { duration: transitions.duration.shortest }),
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
  taxesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: 8,

    '& > span': {
      fontWeight: 500,
      fontSize: 14,
      color: '#707070',
      lineHeight: '16px',

      '&:not(:last-of-type)': {
        paddingRight: 8,
        borderRight: '1px solid #C4C4C4',
        marginRight: 8,
      },
    },
  },
  buttonValor: {
    minWidth: 90,
    height: 25,
    backgroundColor: '#F94024',
    color: '#fff',
    marginRight: 20,
    fontSize: 9,
  },
  dialogGuaranteeTitle: {
    '& .MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    '& .MuiIcon-root': {
      height: '48px!important',
      width: '254px!important',
    }
  },
  iconGuarantee: {
    height: '48px!important',
    width: '254px!important',
    background: `url(${GuaranteeIcon})`,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
  },
  guaranteeActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: 24,
    padding: 0,
  },
  btnMoreInfo: {
    '& .MuiButton-label': {
      gap: 4,
      lineHeight: 'normal',
      color: '#2296F3',
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 600,
    }
  },
  continueBtn: {
    '&&': {
      margin: 0,
    },
    height: 48,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'math-auto',
    lineHeight: '10px',
  },
  selectGuarantee: {
    '&&': {
      marginLeft: '6px!important',
      gap: 4,
      fontSize: 12,
      fontWeight: 500,
    },

    '& .MuiRadio-root': {
      padding: '0px!important',
    },

    '& .MuiFormControlLabel-label': {
      fontSize: '12px!important',
      width: '100%',
      color: '#7B7F82',
      paddingRight: 24,

      '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
      },
    }
  },

  selectedGuarantee: {
    '& .MuiRadio-colorSecondary.Mui-checked .MuiIconButton-label': {
      color: '#FF5225',
    },

    '& .MuiTypography-root': {
      color: '#FF5225',
      fontWeight: 700,
    }
  },
  radioGuarantee: {
    gap: 8,
  }
}));

export default useStyles;
