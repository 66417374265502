import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, Fab, Fade, Slide, Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  Field, formValueSelector, reduxForm, reset,
} from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { InputRender } from '../../fields';
import {
  getAnswersChatAction,
  sendLikeToChatAction,
  sendQuestionToChatAction,
} from './requests/actions';
import LoadingDark from '../algolia/loadingDark/LoadingDark';
import Types from './requests/types';

const DrawerIntelligence = ({
  getAnswersChat,
  sendQuestionToChat,
  sendLikeToChat,
  answers,
  answer,
  user,
  productId,
  loading,
  question,
  irrelevant,
  relevant,
  disabledRelevant,
  className,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();

  const {
    chatAIBtn,
    chatAIGrid,
    chatAIMain,
    chatAIBoxMain,
    chatAIHeader,
    chatAIContent,
    chatAIFooter,
    closeChatBtn,
    formGrid,
    expandBtn,
    footerBox,
    answerAICard,
    answerAIBox,
    aiIcon,
    aiIconDescription,
    likeIcon,
    dontLikeIcon,
    aiDescriptionBox,
    likeBox,
    chatAIAnswerBox,
    chatAIAnswerCard,
    chatAIContentBox,
    formBox,
  } = classes;

  const handleExpand = () => {
    if (answer) {
      setExpand(!expand);
    }
  };

  const onClose = () => {
    setIsOpen(!isOpen);
  };

  const init = () => {
    getAnswersChat({ productId, userId: user.idVendedor }, {});
    setIsOpen(!isOpen);
  };

  const handleLikeInChat = (resRelevant) => {
    if (!disabledRelevant) {
      sendLikeToChat({ questionId: answer.id, relevant: resRelevant, productId });
    }
  };

  const sendQuestion = () => {
    if (!loading.answer && question) {
      sendQuestionToChat({ productId, userId: user.idVendedor });
      dispatch(reset('questionsAndAnswers/chatAI'));
      setExpand(true);
    }
  };

  useEffect(() => {
    dispatch({ type: Types.CLEAR });
    dispatch(reset('questionsAndAnswers/chatAI'));
  }, []);

  const chatContent = () => (
    <Slide
      direction="left"
      in={isOpen}
      style={{ transformOrigin: '0 0 0' }}
      {...(isOpen ? { timeout: 600 } : {})}
    >
      <Box className={chatAIBoxMain}>
        <Box className={chatAIHeader}>
          <span>
            <strong>LDM</strong>
            Intelligence
          </span>
          <button
            onClick={onClose}
            className={closeChatBtn}
          />
        </Box>
        <Box className={chatAIContent}>
          <Box className={chatAIContentBox}>
            {loading.answers && (
              <LoadingDark />
            )}
            {
              !!answers.length && (
                <Typography variant="h5">
                  Últimas perguntas feitas:
                </Typography>
              )
            }
            {
              !!answers.length && answers.map(({ pergunta, resposta }, index) => (
                <Box className={chatAIAnswerBox}>
                  <Card classes={{ root: chatAIAnswerCard }} key={`${pergunta}-${index}`}>
                    <Typography variant="h6">
                      { pergunta }
                    </Typography>
                    <Typography>
                      { resposta }
                    </Typography>
                    <span className={aiIconDescription}>
                      Respondido por Inteligência Artificial
                    </span>
                  </Card>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Grid className={chatAIFooter} height={expand ? 'auto' : 88}>
          <Box className={footerBox}>
            <Box>
              <button
                type="button"
                onClick={handleExpand}
                className={expandBtn}
                disabled={!answer}
              >
                <span className={`${expand && 'minimize'}`} />
              </button>
              {
                expand && (
                  <Fade
                    in={expand}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(expand ? { timeout: 1000 } : {})}
                  >
                    <Box>
                      {loading.answer ? (
                        <LoadingDark />
                      ) : (
                        answer
                        && (
                        <Card classes={{ root: answerAICard }}>
                          <Box className={answerAIBox}>
                            <Typography variant="h6">
                              { answer.pergunta }
                            </Typography>
                            <Typography>
                              {answer.resposta}
                            </Typography>
                            <Grid className={aiDescriptionBox}>
                              <span className={aiIconDescription}>
                                Respondido por Inteligência Artificial
                              </span>
                              <Box className={likeBox}>
                                <button
                                  type="button"
                                  className={`${dontLikeIcon} ${irrelevant && ('selected')}`}
                                  onClick={() => handleLikeInChat(false)}
                                  disabled={disabledRelevant}
                                />
                                <button
                                  type="button"
                                  className={`${likeIcon} ${relevant && ('selected')}`}
                                  disabled={disabledRelevant}
                                  onClick={() => handleLikeInChat(true)}
                                />
                              </Box>
                            </Grid>
                          </Box>
                        </Card>
                        )
                      )}
                    </Box>
                  </Fade>
                )
              }
            </Box>
            <Box className={formBox}>
              <form onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              >
                <Grid item xs={12} className={formGrid}>
                  <Field
                    name="question"
                    component={InputRender}
                    type="input"
                    disabled={false}
                    variant="outlined"
                  />
                </Grid>
              </form>
              <Button
                type="button"
                disabled={loading.answer || loading.answers || !question}
                onClick={sendQuestion}
              >
                <span className={aiIcon} />
                Perguntar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Slide>
  );

  return (
    <Grid className={`${chatAIGrid} ${isOpen && 'open'} ${className}`}>
      {
        isOpen ? (
          <div className={`${chatAIMain} ${className}`}>
            {chatContent()}
          </div>
        ) : (
          <Fab
            classes={{ root: `${chatAIBtn} ${className}` }}
            variant="extended"
            onClick={init}
          >
            <span className={aiIcon} />
            <strong>LDM</strong>
            Intelligence
          </Fab>
        )
      }
    </Grid>
  );
};

DrawerIntelligence.defaultProps = {
  className: '',
};

DrawerIntelligence.propTypes = {
  getAnswersChat: PropTypes.func.isRequired,
  sendQuestionToChat: PropTypes.func.isRequired,
  sendLikeToChat: PropTypes.func.isRequired,
  answers: PropTypes.instanceOf(Array).isRequired,
  answer: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.instanceOf(Object).isRequired,
  question: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  relevant: PropTypes.bool.isRequired,
  irrelevant: PropTypes.bool.isRequired,
  disabledRelevant: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const mapState = (state) => {
  const {
    answers, answer, relevant, irrelevant, loading, disabledRelevant,
  } = state.intelligence;
  const selector = formValueSelector('questionsAndAnswers/chatAI');
  const question = selector(state, 'question');

  return ({
    answers,
    answer,
    loading,
    question,
    relevant,
    irrelevant,
    disabledRelevant,
    ...state.auth.login,
  });
};

export default connect(mapState, {
  getAnswersChat: getAnswersChatAction,
  sendQuestionToChat: sendQuestionToChatAction,
  sendLikeToChat: sendLikeToChatAction,
})(reduxForm({
  form: 'questionsAndAnswers/chatAI',
})(DrawerIntelligence));
