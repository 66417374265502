import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button, Dialog, DialogContent, DialogTitle, DialogActions, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import CustomLinearProgress from '../customLinearProgress';
import useStyles from './styles';
import { Email } from '@material-ui/icons';
import { getNfeAction, sendNfeEmailAction } from '../../../main/order/detail/actions';
import { Close } from '@material-ui/icons';
import moment from 'moment';

const mapStatus = (indexTrack, tracking) => (
  indexTrack + 1 === tracking.length
    ? 100
    : (100 / (tracking.length - 1)) * indexTrack + 3.5
);

const OrderStatusDesktop = ({ delivery, detailedDelivery, getNfe, sendNfeEmail, dataEntregaAgendadaEscolhida}) => {
  const activeTrack = delivery.tracking.filter((track, index) => (
    delivery.tracking[index + 1]
      ? track.data && !delivery.tracking.some((item, i) => item.data && i > index)
      : track.data
  ))[0];
  const indexTrack = delivery.tracking.indexOf(activeTrack);
  const classes = useStyles({
    colorTracking: activeTrack && activeTrack.corTracking,
  });
  const color = activeTrack ? activeTrack.corTracking : '#ccc';

  const [modalSchedule, setModalSchedule] = useState(false);
  const openModalSchedle = () => setModalSchedule(true);
  const handleCloseModalSchedule = () => setModalSchedule(false);

  const showNfeButton = (track) => {
    if (track.ponto === 'NFS' && delivery.objeto.linkNfe) return true;
  }

  const showMoreDetailsSchedule = (track) => {
    if (track.descricaoTracking.toLowerCase() == 'entrega agendada' && dataEntregaAgendadaEscolhida) return true;
  }

  const showTrackingInfo = (track) => {
    if (['em transporte', 'entrega agendada'].includes(track.descricaoTracking.toLowerCase()) 
      && delivery.objeto.nomeTransportadora 
      && delivery.objeto.numeroObjetoTracking) {
      return true;
    }
  }

  const getExtensiveDate = () => {
    if (!dataEntregaAgendadaEscolhida) return null;

    const date = moment(dataEntregaAgendadaEscolhida, 'DD/MM/YYYY');
    return date.format('DD') + ' de ' + date.format('MMMM');
  }

  return (
    <div>
      {delivery.tracking.length ? (
        <>
          <Dialog
            open={modalSchedule}
            className={classes.dialogSchedule}
            onClose={handleCloseModalSchedule}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle className={classes.dialogScheduleTitle}>
              <Box className={classes.dialogScheduleBoxTitle}>
                Entrega Agendada
                <IconButton onClick={handleCloseModalSchedule} className={classes.dialogScheduleClose}><Close fontSize="small"/></IconButton>
              </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogScheduleContent}>
              <Typography>
                Você escolheu a entrega agendada! Seu pedido será separado próximo à data que você selecionou,
                garantindo que chegue dentro do prazo desejado. Fique tranquilo, seu produto estará a caminho
                no momento certo.
              </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogScheduleActions}>
              <Typography>Chegará até <span>{getExtensiveDate()}</span></Typography>
            </DialogActions>
          </Dialog>

          <CustomLinearProgress
            color={activeTrack.corTracking}
            value={mapStatus(indexTrack, delivery.tracking)}
            classes={{ root: classes.trackingBar }}
          />

          <Box display="flex" justifyContent="space-between">
            {delivery.tracking.map((track, index) => (
              <Box className={classes.statusItem} key={`track-${index + 1}`}>
                <Typography
                  component="h2"
                  variant="caption"
                  className={clsx({
                    [classes.active]: indexTrack === index,
                  })}
                >
                  <Box color={(indexTrack === index && color) || null}>
                    {track.descricaoTracking}
                  </Box>
                </Typography>

                <Typography component="h2" variant="caption">
                  {track.data}
                </Typography>

                {showNfeButton(track) && (
                  <Box className={classes.columnInfo}>
                    <Typography
                      mt={6}
                      variant="caption"
                    >
                      NFe: {delivery.objeto.nfNumero}
                    </Typography>
                    <Typography
                      mt={6}
                      variant="caption"
                    >
                      Série: {delivery.objeto.nfSerie}
                    </Typography>
                    <Button
                      className={classes.nfeButton}
                      target="_blank"
                      onClick={() => getNfe(delivery.idEntrega)}
                      variant="outlined"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      Ver Nota Fiscal
                    </Button>
                    <Button
                      className={classes.nfeButton}
                      target="_blank"
                      onClick={() => sendNfeEmail(delivery.idEntrega)}
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      <Email fontSize='small' />
                      Enviar por Email
                    </Button>
                  </Box>
                )}

                {showTrackingInfo(track) && (
                  <Box mt={1} className={classes.columnInfo}>
                    <Typography
                      mt={6}
                      variant="caption"
                    >
                      Transportadora: {delivery.objeto.nomeTransportadora}
                    </Typography>
                    <Typography
                      mt={6}
                      variant="caption"
                    >
                      Cod Rastreio: {delivery.objeto.numeroObjetoTracking}
                    </Typography>
                  </Box>
                )}
                {showMoreDetailsSchedule(track) && (
                  <Box mt={1}>
                    <Button
                      onClick={openModalSchedle}
                      color="secondary"
                      variant="outlined"
                      disableElevation
                    >
                      Saiba Mais
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          {delivery.idEntrega &&
            <Button
              style={{marginTop: 12}}
              color="primary"
              variant="contained"
              onClick={() => detailedDelivery(delivery.idEntrega)}
              disableElevation
            >
              Entrega detalhada
            </Button>
          }
        </>
      ) : (
        'Nenhum status de tracking encontrado'
      )}
    </div>
  );
};

OrderStatusDesktop.propTypes = {
  delivery: PropTypes.instanceOf(Object).isRequired,
  detailedDelivery: PropTypes.func,
  dataEntregaAgendadaEscolhida: PropTypes.instanceOf(Object).isRequired,
  getNfe: PropTypes.func.isRequired,
};

const mapState = (state) => state.main;

export default connect(mapState, {
  getNfe: getNfeAction,
  sendNfeEmail: sendNfeEmailAction
})(OrderStatusDesktop);
