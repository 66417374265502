import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth/reducer';
import main from './main/reducers';
import authClient from './main/authClient/login/reducers';
import register from './main/authClient/register/reducers';
import algolia from './shared/components/algolia/reducers';
import intelligence from './shared/components/drawerIntelligence/requests/reducers';
import keyConsults from './main/client/keyConsults/reducers';

export default combineReducers({
  form: formReducer,
  auth,
  authClient,
  register,
  main,
  algolia,
  intelligence,
  keyConsults,
});
