import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  Button, Box, Tooltip,
  DialogTitle,
} from '@material-ui/core';

import moment from 'moment';
import { connect } from 'react-redux';
import useStyles from './styles';
import Selectable from '../../../main/checkoutsAndOrders/Selectable';

import { Email } from '@material-ui/icons';
import { getNfeAction, sendNfeEmailAction } from '../../../main/order/detail/actions';

moment.locale('pt-br');
const OrderDetailsModal = ({
  detailed,
  open,
  onClose,
  trackingData,
  getNfe,
  sendNfeEmail,
}) => {
  const classes = useStyles();
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (trackingCode) => {
    navigator.clipboard.writeText(trackingCode).catch((err) => console.log(err.message));
    handleTooltip();
  };

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxHeight: 300,
        },
      }}
    >
      {!!trackingData && (
        <Box className={classes.trackingDialogBox}>
          <DialogTitle className={classes.trackingTitle}>
            <Box className={classes.trackingBox}>
              <span className={classes.tracking}>{`Pedido n.º ${trackingData.codPedido}`}</span>
              <span className={classes.tracking}>{`Entrega n.º ${trackingData.idEntrega}`}</span>
            </Box>
          </DialogTitle>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            disableElevation
          >
            Fechar
          </Button>
        </Box>
      )}
      <TableContainer sx={{ maxHeight: 240 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.theader}>
            <TableRow>
              <TableCell className={classes.headerCell}>
                Data
              </TableCell>
              <TableCell className={classes.headerCell}>
                Descrição
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBody}>
            {detailed && detailed.map((row) => (
              <TableRow>
                <TableCell className={classes.cell}>
                  {moment((row.dataOcorrencia || row.data), 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}
                </TableCell>
                <TableCell className={classes.cell}>
                  <Selectable>
                    <span>
                      {(row.descricaoPonto || row.descricaoTracking).toUpperCase()}
                    </span>
                    {row.ponto === 'NFS' && (
                      <>
                        <Box className={classes.subtitle}>
                          <span>{`N.º ${row.numeroNota} - série: ${row.serieNota}`}</span>
                        </Box>
                        <Box className={classes.nfeActions}>
                          <Button
                            disableRipple
                            disableElevation
                            variant="outlined"
                            color="primary"
                            classes={{ root: classes.nfeBtn }}
                            onClick={() => getNfe(row.idEntrega)}
                          >
                            Ver nota fiscal
                          </Button>
                          <Button
                            disableRipple
                            disableElevation
                            variant="contained"
                            color="primary"
                            classes={{ root: classes.nfeBtn }}
                            onClick={() => sendNfeEmail(row.idEntrega)}
                          >
                            <Email fontSize='small' />
                            Enviar por Email
                          </Button>
                        </Box>
                      </> 
                    )}
                    {row.ponto === 'ETR' && (
                      <Box className={classes.subtitle}>
                        <span>{`Transportadora: ${row.correio}`}</span>
                        <Box className={classes.trackingCodeBox}>
                          <span>{`Cód. rastreio: ${row.numeroObjetoTracking}`}</span>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltip}
                            open={openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                            title="Código copiado"
                            placement="top"
                          >
                            <button
                              type="button"
                              className={classes.btnTrackingCode}
                              onClick={() => copyToClipboard(row.numeroObjetoTracking)}
                            >
                              <div className={classes.copyIcon} />
                            </button>
                          </Tooltip>
                        </Box>
                      </Box>
                    )}
                  </Selectable>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!trackingData && (
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          disableElevation
        >
          Fechar
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
};

OrderDetailsModal.defaultProps = {
  trackingData: null,
};

OrderDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  detailed: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  getNfe: PropTypes.func.isRequired,
  trackingData: PropTypes.instanceOf(Object),
};

const mapState = (state) => state.main;

export default connect(mapState, {
  getNfe: getNfeAction,
  sendNfeEmail: sendNfeEmailAction
})(OrderDetailsModal);
