import { cnpj, cpf } from 'cpf-cnpj-validator';

const getInvalidOrderMessage = ({ checkout, loading, document, user }) => {
  let semEstoque = false;
  let productId = null;

  const filiais = checkout.itemsFilials;
  if (filiais) {
    filiais.map((filial) => {
      filial.items.map((item) => {
        if (item.isSingleDelivery) {
          var estoque = 0;

          const itemFilial = item.filials.filter((itemFilial) => itemFilial.idFilial === filial.idFilial)[0];

          if (item.typeStock === "X") {
            const filialCrossDocking = item.filials.find((filial) => filial.idEstabelecimento === 9);

            estoque += filialCrossDocking.estoque;
          }

          if (itemFilial && itemFilial.estoque >= item.quantity) {
            estoque += itemFilial.estoque;
          }

          if (estoque < 1) semEstoque = true;

          productId = item.productId;
        } else {
          const estoqueSelecionado = item.quantity;
          const estoqueTotal = item.filials.reduce((accml, filial) => accml + filial.estoque, 0);

          if (estoqueSelecionado > estoqueTotal) semEstoque = true;

          productId = item.productId;
        }
      });
    })
  }

  if (semEstoque) {
    return 'Sem estoque do item - ' + productId;
  }

  for(var i=0; i < checkout.payments?.length; i++){
    if (checkout.payments[i].paymentStatus === 1 && checkout.payments[i].type == 'CC') {
      return 'Pagamento aguardando aprovação';
    }
  }

  if (!checkout.itemsFilials?.length) {
    return 'O carrinho está vazio';
  }

  if (!checkout.resume) {
    return 'Orçamento inválido';
  }
  if (checkout.resume.remainder || checkout.resume.remainderInstallment || checkout.resume.remainder < 0 || checkout.resume.remainderInstallment < 0) {
    return 'Os pagamentos não igualaram o valor da compra';
  }
  if (checkout.resume.idSeller != user.idVendedor && user.idVendedor !== 1381 ) {
    return 'O orçamento só pode ser finalizado pelo vendedor ' + checkout.resume.sellerName;
  }
  if (loading) {
    return 'Carregando...';
  }
  if (!checkout.customer.address.zipCode) {
    return 'O cliente não possui um endereço valido';
  }
  if (!document) {
    return 'O cliente não possui documento';
  }
  if (String(document).length === 11 && !cpf.isValid(String(document))) {
    return 'O CPF do cliente é inválido';
  }
  if (String(document).length > 11 && !cnpj.isValid(String(document))) {
    return 'O CNPJ do cliente é inválido';
  }
  if (checkout.resume.discountStatus == 'Desconto Aprovado' || checkout.resume.discountStatus == 'Desconto aprovado') {
  } else {
    return 'A solicitação de desconto não foi aprovada';
  }
  if (checkout.orderId) {
    return `O orçamento ja possui pedido fechado: ${checkout.orderId}`;
  }
  if (checkout.payments) {
    const havePixWaiting = checkout.payments.some((payment) => (
      payment.type === 'PX' && payment.paymentStatus === 1
    ));

    if (havePixWaiting) return 'O orçamento possui pagamentos em espera';
  }

  return null;
};

export default getInvalidOrderMessage;
