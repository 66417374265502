import React, { useState } from 'react';
import {
  Divider,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Delete } from '@material-ui/icons';
import CheckoutItem from '../checkoutItem/CheckoutItem';
import { toMoney } from '../../../../shared/utils/parsers';
import {
  removeProductAction,
  removeProductListAction,
  updateQuantityAction,
  itemDiscountAction,
  updateWarrantyAction,
} from '../../actions';

const CheckoutItems = ({
  checkout: {
    itemsFilials,
    orderId,
  },
  checkout,
  loading,
  removeProduct,
  removeProductList,
  drawer,
  updateQuantity,
  updateWarranty,
  idCheckout,
  itemDiscount,
  enqueueSnackbar,
  activeFilial,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemsSelected = (event) => {
    const { value, checked } = event.target;
    const valueNumber = Number(value);
    if (checked) {
      setSelectedItems((prevItems) => [...prevItems, valueNumber]);
    } else {
      setSelectedItems((prevItems) => prevItems.filter((item) => item !== valueNumber));
    }
  };

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const handleRemove = () => {
    setModalConfirmation(true);
  };

  const handleRemoveAction = () => removeProductList(idCheckout, selectedItems).then((r) => {
    handleCloseModal();
  });

  const handleCloseModal = () => {
    setSelectedItems([]);
    setModalConfirmation(false);
  };

  return (
    <>
      <Dialog open={modalConfirmation}>
        <DialogTitle>Excluir itens selecionados</DialogTitle>
        <DialogContent>
          Deseja realmente excluir os itens selecionados?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveAction} color="primary">Sim</Button>
          <Button onClick={handleCloseModal} variant="contained" color="primary">Não</Button>
        </DialogActions>
      </Dialog>
      <Box position="absolute" display="flex" right={0} fontSize={12} height={30} color="text.secondary">
        {selectedItems.length > 0 && (
          <Button
            size="small"
            onClick={handleRemove}
          >
            Excluir Selecionados
            <Delete />
          </Button>
        )}
      </Box>
      {itemsFilials.map(((filial) => (
        <Box key={filial.establishmentId}>
          <Box display="flex" alignItems="center" justifyContent="space-between" p="2px 8px">
            <Box display="flex" gridGap={12}>
              <Typography variant="subtitle1">
                <Box fontWeight={500}>
                  {filial.name}
                </Box>
              </Typography>
              <Typography variant="subtitle1">
                {toMoney(filial.freight)}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {filial.items.map((item) => (
            <CheckoutItem
              currentFilial={{idFilial: filial.idFilial, nomeFilial: filial.name}}
              key={item.idSku}
              isSingleDelivery={item.isSingleDelivery}
              codKit={item.codKit !== null ? item.codKit : 0}
              productId={item.productId}
              name={item.name}
              image={item.image}
              quantity={item.quantity}
              total={item.total}
              totalOnTime={item.totalOnTime}
              subTotal={item.subTotal}
              subTotalOnTime={item.subTotalOnTime}
              margin={item.margin}
              updateItem={({
                productId, quantity, pplHash, warrantyId, porcentagemDesconto, total,
              }) => updateQuantity({
                idCheckout, productId, quantity, pplHash, warrantyId, porcentagemDesconto, total,
              })}
              updateWarranty={({
                productId, quantity, pplHash, warrantyId, porcentagemDesconto, total,
              }) => updateWarranty({
                idCheckout, productId, quantity, pplHash, warrantyId, porcentagemDesconto, total,
              })}
              drawerIsOpened={drawer}
              onSubmit={itemDiscount}
              loading={loading}
              discount={item.discount}
              discountPercent={item.discountPercent}
              checkoutId={idCheckout}
              checkout={checkout}
              onRemove={removeProduct}
              allowedStock={item.allowedStock}
              ppl={item.ppl}
              pplHash={item.pplHash}
              pplActive={item.pplActive}
              pplExpirated={item.pplExpirated}
              pplStock={item.pplStock}
              enqueueSnackbar={enqueueSnackbar}
              companyName={item.companyName}
              idSeller={item.idSeller}
              model={item.model}
              idItemBseller={item.idItemBseller}
              typeStock={item.typeStock}
              deliveryTime={item.deliveryTime}
              freight={item.freight}
              shippingCompany={item.shippingCompany}
              filials={item.filials}
              pointsOnTime={item.pointsOnTime}
              isPma={item.isPma}
              activeFilial={activeFilial}
              orderId={orderId}
              virtualStorage={item.virtualStorage}
              garantias={item.garantias}
              onItemsSelected={handleItemsSelected}
              selectedItems={selectedItems}
              stTax={item.stTax}
              fcpTax={item.fcpTax}
            />
          ))}
        </Box>
      )))}

      {!itemsFilials.length && (
        <>
          <Box p={2}>
            <Typography variant="h6">
              O carrinho está vazio
            </Typography>

            <Typography variant="body2">
              Cliquem em "+ ADICIONAR MAIS ITENS" para começar a preencher este carrinho.
            </Typography>
          </Box>

          <Divider />
        </>
      )}
    </>
  );
};

CheckoutItems.propTypes = {
  removeProduct: PropTypes.func.isRequired,
  removeProductList: PropTypes.func.isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  activeFilial: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  drawer: PropTypes.bool.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  itemDiscount: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  idCheckout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

const mapState = (state) => {
  const { loading } = state.main.checkout.geral;
  const { loading: loadingPayment } = state.main.checkout.payment;
  const { filial } = state.main.app;

  return {
    ...state.main.checkout.geral,
    drawer: state.main.app.drawer,
    loading: loadingPayment || loading,
    enqueueSnackbar: state.main.app.snack.enqueueSnackbar,
    activeFilial: filial,
  };
};

export default connect(mapState, {
  removeProduct: removeProductAction,
  removeProductList: removeProductListAction,
  updateQuantity: updateQuantityAction,
  updateWarranty: updateWarrantyAction,
  itemDiscount: itemDiscountAction,
})(CheckoutItems);
