import types from './types';
import { getOrder, getLinkFile, postFileOc } from './repository';
import { getClient } from '../../../shared/repositories/clientRepository';
import resolveError from '../../../shared/utils/resolveError';
import apiService from '../../../shared/services/apiService';
import apiCaixaService from '../../../shared/services/apiCaixaService';

export const resetOrderAction = () => ({
	type: types.RESET_CHECKOUT,
});

export const getLinkFileAction = orderId => async (dispatch, getState) => {
	dispatch({ type: types.GET_LINK_FILE_REQUEST });

	try {
		const linkFile = await getLinkFile(orderId);

		dispatch({
			type: types.GET_LINK_FILE_SUCCESS,
			payload: Object.values(linkFile),
		});
	} catch (e) {
		// const { snack } = getState().main.app;
		// snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.GET_LINK_FILE_FAIL });
	}
};

export const submitFileAction = (orderId, arquivo) => async (dispatch, getState) => {
	dispatch({ type: types.SUBMIT_LINK_FILE_REQUEST });
	const { snack } = getState().main.app;
	const formData = new FormData();

	formData.append('arquivoCompra', arquivo);

	try {
		await postFileOc(orderId, formData);
		snack.enqueueSnackbar('Ordem de compra enviada!', { variant: 'success' });
		dispatch({ type: types.SUBMIT_LINK_FILE_SUCCESS });
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.SUBMIT_LINK_FILE_FAIL });
	}
};

export const getOrderAction = (orderId, customerId) => async (dispatch, getState) => {
	dispatch({ type: types.GET_ORDER_REQUEST });
	try {
		const order = await getOrder(orderId, customerId);

		dispatch({
			type: types.GET_ORDER_SUCCESS,
			payload: {
				order,
			},
		});
	} catch (e) {
		const { snack } = getState().main.app;
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.GET_ORDER_FAIL });
	}
};

export const getCustomerAction = customerId => async (dispatch, getState) => {
	dispatch({ type: types.GET_CUSTOMER_REQUEST });
	try {
		const customer = await getClient(customerId);

		dispatch({
			type: types.GET_CUSTOMER_SUCCESS,
			payload: {
				customer,
			},
		});
	} catch (e) {
		const { snack } = getState().main.app;
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.GET_CUSTOMER_FAIL });
	}
};

export const printBilletAction = orderId => async (dispatch, getState) => {
	dispatch({ type: types.PRINT_BILLET_REQUEST });
	const { snack } = getState().main.app;
	try {
		const {
			data: {
				results: { message, value },
			},
		} = await apiService.get(`pedidos/${orderId}/boleto`);

		snack.enqueueSnackbar(message, { variant: 'success' });
		window.open(value, '_blank');
		dispatch({ type: types.PRINT_BILLET_SUCCESS });
	} catch (e) {
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		dispatch({ type: types.PRINT_BILLET_FAIL });
	}
};

export const getNfeAction = idEntrega => async (dispatch, getState) => {
	dispatch({ type: types.NFE_REQUEST });
	try {
		const { data } = await apiService.get(`nfe/download/${idEntrega}`, {
			responseType: 'blob',
		});

		const file = new Blob([data], { type: 'application/pdf' });

		window.open(URL.createObjectURL(file));
		dispatch({ type: types.NFE_SUCCESS });
	} catch (_e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(_e), {
      variant: 'error',
    });
		dispatch({ type: types.NFE_FAILURE });
    dispatch({ type: types.CLOSE_CUSTOM_LOADING });
	}
};

export const sendNfeEmailAction = idEntrega => async (dispatch, getState) => {
	dispatch({ type: types.NFE_REQUEST });
	try {
		const { snack } = getState().main.app;

		const { data: { message } } = await apiService.get(`nfe/envio-email/${idEntrega}`);

		if (message) snack.enqueueSnackbar(message, { variant: 'success' });
		dispatch({ type: types.NFE_SUCCESS });
	} catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), {
      variant: 'error',
    });
    dispatch({ type: types.NFE_FAILURE });
	}
};

export const getPdfAction = orderId => async dispatch => {
	dispatch({ type: types.PDF_REQUEST });
	try {
		const { data } = await apiService.get(`relatorios/pedidos/${orderId}/detalhes`, {
			responseType: 'blob',
		});

		const file = new Blob([data], { type: 'application/pdf' });

		window.open(URL.createObjectURL(file));
		dispatch({ type: types.PDF_SUCCESS });
	} catch (_e) {
		dispatch({ type: types.PDF_FAIL });
	}
};

export const getDetailsFromCaixaAction = orderId => async dispatch => {
	dispatch({ type: types.GET_ORDER_CAIXA_REQUEST });
	try {
		const {
			data: {
				content
			},
		} = await apiCaixaService.get(`pedidos/order-details/${orderId}`);

		dispatch({type: types.GET_ORDER_CAIXA_SUCCESS, 
			payload: { 
				order: {
					...content,
					bitTesouraria: true
				}
			}
		});
	} catch (_e) {
		dispatch({ type: types.GET_ORDER_CAIXA_FAIL });
		throw new Error("Erro ao buscar detalhes do pedido no terminal de caixa");
	}
};


export const getReimpressaoCupomFiscalAction = orderId => async (dispatch, getState) =>  {
	try {
		const { data } = await apiCaixaService.get(`/nfce/print/${orderId}`);

		const win = window.open();
		win.focus();
		win.document.open();
		win.document.write(data);
	} catch (e) {
		const { snack } = getState().main.app;
		snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
	}
}

export const sendPdfEmailAction =
	({ orderId, customerId }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.PDF_REQUEST });
		try {
			const { snack } = getState().main.app;
			const {
				data: { results },
			} = await apiService.post('relatorios/envio-por-email', {
				tipo: 'pedido',
				codCliente: customerId,
				dadosRelatorio: [
					{
						uri: '/relatorios/pedidos/{codPedido}/detalhes',
						id: 'pedidoDetalhe',
						parameters: {
							parametro: 'codPedido',
							tipoParametro: 'url',
							valorParametro: orderId,
						},
					},
				],
			});
			if (!results.response) throw new Error(results.message);
			snack.enqueueSnackbar(results.message, { variant: 'success' });

			dispatch({ type: types.PDF_SUCCESS });
		} catch (_e) {
			dispatch({ type: types.PDF_FAIL });
		}
	};

export const getDetailedDeliveryAction =
	({ deliveryId, orderDate }) =>
	async (dispatch, getState) => {
		dispatch({ type: types.DETAILED_DELIVERY_REQUEST });
		try {
			const defaultError = 'Consulta indisponível, tente novamente!';

			if (!deliveryId || !orderDate) throw new Error(defaultError);

			const { data, message } = await apiService.get(`tracking-detalhado/entrega/${deliveryId}`);

			if (message) throw new Error(message);
			if (!data.length) throw new Error(defaultError);

			dispatch({
				type: types.DETAILED_DELIVERY_SUCCESS,
				payload: {
					detailed: data,
				},
			});
		} catch (e) {
			dispatch({ type: types.DETAILED_DELIVERY_FAIL });
			const { snack } = getState().main.app;
			snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
		}
	};

export const resetDetailedDeliveryAction = () => ({
	type: types.RESET_DETAILED_DELIVERY,
});
