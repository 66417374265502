export default {
  GET_ANSWERS_CHAT_REQUEST: '@intelligence/GET_ANSWERS_CHAT_REQUEST',
  GET_ANSWERS_CHAT_SUCCESS: '@intelligence/GET_ANSWERS_CHAT_SUCCESS',
  GET_ANSWERS_CHAT_FAILURE: '@intelligence/GET_ANSWERS_CHAT_FAILURE',

  SEND_QUESTION_TO_CHAT_REQUEST: '@intelligence/SEND_QUESTION_TO_CHAT_REQUEST',
  SEND_QUESTION_TO_CHAT_SUCCESS: '@intelligence/SEND_QUESTION_TO_CHAT_SUCCESS',
  SEND_QUESTION_TO_CHAT_FAILURE: '@intelligence/SEND_QUESTION_TO_CHAT_FAILURE',

  SEND_LIKE_TO_CHAT_REQUEST: '@intelligence/SEND_LIKE_TO_CHAT_REQUEST',
  SEND_LIKE_TO_CHAT_SUCCESS: '@intelligence/SEND_LIKE_TO_CHAT_SUCCESS',
  SEND_LIKE_TO_CHAT_FAILURE: '@intelligence/SEND_LIKE_TO_CHAT_FAILURE',

  CLEAR: '@intelligence/CLEAR',
  CLEAR_ANSWER: '@intelligence/CLEAR_ANSWER',
};
