export default {
  GET_ORDER_REQUEST: '@order-detail/GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: '@order-detail/GET_ORDER_SUCCESS',
  GET_ORDER_FAIL: '@order-detail/GET_ORDER_FAIL',
  GET_LINK_FILE_REQUEST: '@order-detail/GET_LINK_FILE_REQUEST',
  GET_LINK_FILE_SUCCESS: '@order-detail/GET_LINK_FILE_SUCCESS',
  GET_LINK_FILE_FAIL: '@order-detail/GET_LINK_FILE_FAIL',
  SUBMIT_LINK_FILE_REQUEST: '@order-detail/SUBMIT_LINK_FILE_REQUEST',
  SUBMIT_LINK_FILE_SUCCESS: '@order-detail/SUBMIT_LINK_FILE_SUCCESS',
  SUBMIT_LINK_FILE_FAIL: '@order-detail/SUBMIT_LINK_FILE_FAIL',
  GET_CUSTOMER_REQUEST: '@order-detail/GET_CUSTOMER_REQUEST',
  GET_CUSTOMER_SUCCESS: '@order-detail/GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAIL: '@order-detail/GET_CUSTOMER_FAIL',
  PRINT_BILLET_REQUEST: '@order-detail/PRINT_BILLET_REQUEST',
  PRINT_BILLET_SUCCESS: '@order-detail/PRINT_BILLET_SUCCESS',
  PRINT_BILLET_FAIL: '@order-detail/PRINT_BILLET_FAIL',
  UPDATE_INFO_REQUEST: '@order-detail/UPDATE_INFO_REQUEST',
  UPDATE_INFO_SUCCESS: '@order-detail/UPDATE_INFO_SUCCESS',
  UPDATE_INFO_FAIL: '@order-detail/UPDATE_INFO_FAIL',
  RESET_CHECKOUT: '@order-detail/RESET_CHECKOUT',
  PDF_REQUEST: '@order-detail/PDF_REQUEST',
  PDF_SUCCESS: '@order-detail/PDF_SUCCESS',
  PDF_FAIL: '@order-detail/PDF_FAIL',
  DETAILED_DELIVERY_REQUEST: '@order-detail/DETAILED_DELIVERY_REQUEST',
  DETAILED_DELIVERY_SUCCESS: '@order-detail/DETAILED_DELIVERY_SUCCESS',
  DETAILED_DELIVERY_FAIL: '@order-detail/DETAILED_DELIVERY_FAIL',
  GET_ORDER_CAIXA_REQUEST: '@order-detail/GET_ORDER_CAIXA_REQUEST',
  GET_ORDER_CAIXA_SUCCESS: '@order-detail/GET_ORDER_CAIXA_SUCCESS',
  GET_ORDER_CAIXA_FAIL: '@order-detail/GET_ORDER_CAIXA_FAIL',
  RESET_DETAILED_DELIVERY: '@order-detail/RESET_DETAILED_DELIVERY',
  NFE_REQUEST: '@order-detail/NFE_REQUEST',
  NFE_FAILURE: '@order-detail/NFE_FAILURE',
  NFE_SUCCESS: '@order-detail/NFE_SUCESS',
};
