import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Box,
} from '@material-ui/core';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { getClientDataAction } from './actions';
import CircularLoading from '../../shared/components/loading/CircularLoading';
import { push, replace } from '../../history';
import {
  CardPaper,
  CustomerInfoPage,
  MenuClientSpace,
  TabPanel,
} from './_components/index';
import Addresses from './pages/addressesPage/Addresses';
import { getClient } from '../../shared/services/authClient';

const ClientPage = ({
  getClientData,
  client,
  info,
  match: {
    params: { tabName },
  },
}) => {
  const {
    cardGrid,
    boxAlign,
    clientMenuBox,
    clientMenuDataBox,
  } = useStyles();

  const routeMain = window.location.pathname.split('/')[1];
  const [tabValue, setTabValue] = useState(0);

  useLayoutEffect(() => {
    if (!client || (client && !client.confirmAddress && tabName !== 'addresses')) {
      push('/client-login');
    } else if (client && tabName === 'registration-data') {
      getClientData(client.codCliente, client.tipoPessoa);
    }
  }, [client && tabName]);

  const handleCloseUpdateModal = () => getClientData(client.codCliente, client.tipoPessoa);

  const components = [
    {
      component: (
        <CustomerInfoPage
          onCloseModal={handleCloseUpdateModal}
          customerInfo={info.client}
        />
      ),
      tabName: 'registration-data',
    },
    {
      component: <Addresses />,
      tabName: 'addresses',
    },
    {
      component: <Box>Pedidos do Cliente</Box>,
      tabName: 'client-orders',
    },
    {
      component: <Box>Favoritos</Box>,
      tabName: 'favorites',
    },
    {
      component: <Box>Vales</Box>,
      tabName: 'coupons-and-vouchers',
    },
    {
      component: <Box>Solicitação de crédito</Box>,
      tabName: 'credit-request',
    },
  ];

  const handleTabValue = (e, newTabValue) => {
    setTabValue(newTabValue);

    const tabSelected = components.find(
      (_, index) => index === newTabValue,
    );
    replace(`/${routeMain}/${tabSelected.tabName}`);
  };

  useEffect(() => {
    const tabIndexSelected = components.findIndex(
      (component) => component.tabName.match(tabName),
    );
    setTabValue(tabIndexSelected);
  }, [tabName]);

  return (
    <Box className={boxAlign}>
      <CircularLoading open={info.isLoading} message="Buscando dados do cliente" />
      <Box className={cardGrid}>
        <Box className={clientMenuBox}>
          <CardPaper>
            <MenuClientSpace
              handleTabChange={handleTabValue}
              tabValue={tabValue}
            />
          </CardPaper>
        </Box>
        <Box className={clientMenuDataBox}>
          {
            components.map(({ component }, index) => (
              <TabPanel index={index} value={tabValue}>
                {component}
              </TabPanel>
            ))
           }
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  let { client } = state.authClient;
  const info = state.main.clientInfo;

  client = getClient() || client;
  return {
    client,
    info,
  };
};

ClientPage.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  getClientData: PropTypes.func.isRequired,
  info: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, {
  getClientData: getClientDataAction,
})(ClientPage);
