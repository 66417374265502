import React from 'react';
import * as PropTypes from 'prop-types';

import useStyles from './styles';
import { Tooltip } from '@material-ui/core';

const CardInfo = ({
  label,
  value,
  disabled,
  info,
}) => {
  const {
    infoContent,
    content,
    customInfo,
    labelField,
    valueField,
    disabledField
  } = useStyles();

  return (
    <div className={infoContent}>
      <div className={content}>
        <span className={labelField}>{label}</span>
        <span className={`${valueField} ${disabled && disabledField}`}>{value}</span>
      </div>
      {info && (
        <Tooltip title={info.tooltip}>
          <span className={`${customInfo}`}>{info.icon && <span className={info.icon}/>}{info.text}</span>
        </Tooltip>
      )}
    </div>
  );
};

CardInfo.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  info: PropTypes.instanceOf(Object),
};

export default CardInfo;