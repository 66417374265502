import { makeStyles } from '@material-ui/core';
import { EditIcon } from '../../../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  addressGrid: {
    '& $addressBox': {
      borderBottom: '1px solid #CFCFD4',
      paddingBottom: 16,
      marginBottom: 16,

      '&:last-child': {
        border: 0,
        padding: 0,
        margin: 0,
      },
    },
  },
  addressBox: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      color: '#7B7F82',
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,

    '& .main': {
      background: '#21AD34',
      height: 25,
      display: 'inline-flex',
      borderRadius: 4,
      padding: '4px 16px',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '18px',
      color: '#FFFFFF !important',
      fontWeight: 600,
    },

    '& .select': {
      background: 'none',
      color: '#FE5000 !important',
      padding: 0,
    },

    '& .inactive': {
      color: '#7B7F82 !important',
    },
  },
  addressMain: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    fontWeight: 600,
    color: '#242424 !important',

    '&.inactive': {
      color: '#7B7F82 !important',
    },
  },
  activeInactiveBtn: {
    width: 36,
    height: 20,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#FFFFFF',
        '& + .MuiSwitch-track': {
          backgroundColor: '#FE5000',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
          backgroundColor: 'gray',
        },
      }, 
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#FE5000',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 'gray',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      color: '#FFFFFF',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#7B7F82',
      opacity: 1,
    },
  },
  editBtn: {
    backgroundColor: '#FE5000',
    '-webkit-mask-image': `url(${EditIcon})`,
    maskImage: `url(${EditIcon})`,
    minWidth: 18,
    height: 18,
    maskSize: 18,

    '&:hover': {
      backgroundColor: '#FE5000',
    },
  },
  activeInactiveForm: {
    margin: 0,
  },
  infoClientBox: {
    display: 'flex',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 10,
    },
  },
  mainSelectBtn: {
    padding: 0,
    textTransform: 'none',
    minWidth: 'auto',
  },
}));

export default useStyles;
