import React from 'react';

import {
  Backdrop,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import loadcar from '../../../assets/loadcar.svg';
import useCount from '../../services/useCount';

const CircularLoading = ({
  open, message
}) => {
  const classes = useStyles();

  const { counting } = useCount(0);

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <div className={classes.loadCar}>
          <span className={classes.svgLogo} style={{ maxHeight: 24}}>
            <img src={loadcar} className={classes.svgLogoImg} alt="loader" />
          </span>
          <span className={classes.svgLogoBorder}></span>
          <div className={classes.label}>{(message || 'Carregando') + '.'.repeat(counting)}</div>
        </div>
      </Backdrop>
    </>
  );
};

CircularLoading.defaultProps = {
  open: false,
  message: 'Carregando',
  blur: false,
};

CircularLoading.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  blur: PropTypes.bool
};

export default CircularLoading;
