import { makeStyles } from '@material-ui/core';
import { FolhasIcon } from '../../../../assets';

const useStyles = makeStyles(() => ({
  infoContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  customInfo: {
    background: '#21AD34',
    height: 32,
    gap: 4,
    display: 'inline-flex',
    borderRadius: 4,
    padding: '4px 16px',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '18px',
    color: '#FFFFFF !important',
    fontWeight: 600,
    justifyContent: 'center',
    marginTop: 6,
    '& .produtor': {
      background: `url(${FolhasIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 18,
      height: 18,
    }
  },
  labelField: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    color: '#7B7F82',
    marginBottom: 6,
  },
  valueField: {
    fontSize: 16,
    fontWeight: 600,
    color: '#242424',
    minHeight: 22,
  },
  disabledField: {
    color: '#7B7F82',
  },
}));

export default useStyles;
