import { handleActions, combineActions } from 'redux-actions';
import Types from './types';

const INITIAL_STATE = {
  answers: [],
  answer: null,
  page: 1,
  limit: 10,
  loading: {
    answers: false,
    answer: false,
  },
  disabledRelevant: false,
  relevant: false,
  irrelevant: false,
  hasException: null,
};

const stopLoading = combineActions(
  Types.GET_ANSWERS_CHAT_SUCCESS,
  Types.SEND_LIKE_TO_CHAT_SUCCESS,
  Types.SEND_QUESTION_TO_CHAT_SUCCESS,
  Types.GET_ANSWERS_CHAT_FAILURE,
  Types.SEND_LIKE_TO_CHAT_FAILURE,
  Types.SEND_QUESTION_TO_CHAT_FAILURE,
);

const startLoading = combineActions(
  Types.GET_ANSWERS_CHAT_REQUEST,
  Types.SEND_LIKE_TO_CHAT_REQUEST,
  Types.SEND_QUESTION_TO_CHAT_REQUEST,
);

export default handleActions({
  [stopLoading]: (state) => ({
    ...state,
    loading: {
      answers: false,
      answer: false,
    },
  }),
  [startLoading]: (state) => ({
    ...state,
  }),
  [Types.GET_ANSWERS_CHAT_REQUEST]: (state) => ({
    ...state,
    loading: {
      answers: true,
      answer: false,
    },
  }),
  [Types.SEND_LIKE_TO_CHAT_REQUEST]: (state) => ({
    ...state,
    loading: {
      answers: false,
      answer: false,
    },
    disabledRelevant: true,
  }),
  [Types.SEND_QUESTION_TO_CHAT_REQUEST]: (state) => ({
    ...state,
    loading: {
      answers: false,
      answer: true,
    },
    disabledRelevant: false,
    relevant: false,
    irrelevant: false,
  }),
  [Types.GET_ANSWERS_CHAT_REQUEST]: (state) => ({
    ...state,
    loading: {
      answers: true,
      answer: false,
    },
  }),
  [Types.GET_ANSWERS_CHAT_SUCCESS]: (state, action) => {
    const { answers } = action;
    return {
      ...state,
      loading: {
        answers: false,
        answer: false,
      },
      answers,
      page: 0,
    };
  },
  [Types.SEND_QUESTION_TO_CHAT_SUCCESS]: (state, action) => {
    const { answer, answers } = action;
    return {
      ...state,
      loading: {
        answers: false,
        answer: false,
      },
      answer,
      answers,
      page: 0,
    };
  },
  [Types.SEND_LIKE_TO_CHAT_SUCCESS]: (state, action) => {
    const { isRelevant } = action;
    return {
      ...state,
      loading: {
        answers: false,
        answer: false,
      },
      relevant: isRelevant,
      irrelevant: !isRelevant,
      page: 0,
    };
  },
  [Types.CLEAR]: () => ({
    ...INITIAL_STATE,
  }),
  [Types.CLEAR_ANSWER]: (state) => ({
    ...state,
    answer: null,
  }),
}, INITIAL_STATE);
