/* eslint-disable react/require-default-props */
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  oneOfType,
  string,
} from 'prop-types';
import { Close, InfoOutlined, Error as ErrorIcon } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { toMoney, toThousand } from '../../../../shared/utils/parsers';
import tooltipEv from '../../../../assets/tooltip.png';
import StyledButton from '../../../../shared/components/styledButton/StyledButton';
import nitroImg from '../../../../assets/nitro.png';
import useStyles from './style';
import CheckoutItemDialogGuarantee from './CheckoutItemDialogGuarantee';
import ModalEntregaQuebrada from './ModalEntregaQuebrada';
import { getEntregaQuebrada } from '../../../../shared/repositories/checkoutRepository';

const CheckoutItemDetails = ({
  drawerIsOpened, productId, name, showItem, idItemBseller, image, idSeller,
  quantity, filials, activeFilial, ppl, updateItem,
  pplHash, pplStock, enqueueSnackbar, subTotalOnTime, model, companyName,
  loading, orderId, codKit, garantias, shippingCompany, virtualStorage, percent,
  isSingleDelivery, checkoutId, currentFilial, typeStock
}) => {
  const classes = useStyles();
  const { REACT_APP_ENV: ENV } = process.env;
  const [fieldQtd, setFieldQtd] = useState(quantity);
  const [nitro, setNitro] = useState(false);
  const [time, setTime] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (shippingCompany) {
      if (shippingCompany.includes('ENTREGA>30KG') || shippingCompany.includes('GFL D+1') || shippingCompany.includes('GARGOMAIS') || shippingCompany.includes('NITRO')) {
        setNitro(true);
      }
    }
  });

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const activeFilialItem = useMemo(
    () => filials.find((filial) => filial.idFilial === activeFilial.idFilial),
    [filials, activeFilial],
  );

  const filialItem = useMemo(
    () => filials.find((filial) => filial.idFilial === currentFilial.idFilial),
    [filials, activeFilial],
  );

  const filialCrossDocking = useMemo(
    () => filials.find((filial) => filial.idEstabelecimento === 9),
    [filials, activeFilial],
  );

  const garantiaSelecionada = () => {
    let selecionada = 0;
    for (let i = 0; i < garantias.length; i++) {
      if (garantias[i].selecionada) {
        selecionada = garantias[i].idGarantia;
      }
    }
    return selecionada;
  };

  const [valueRadioButton, setValueRadioButton] = useState(garantiaSelecionada());

  const othersStock = useMemo(
    () => filials
      .filter((filial) => filial.idFilial !== activeFilial.idFilial)
      .reduce((accml, filial) => accml + filial.estoque, 0),
    [filials, activeFilial],
  );

  const isCrossDocking = typeStock === "X";
  const filialItemStock = (isCrossDocking && filialCrossDocking?.estoque) || filialItem?.estoque || 0;
  const filialItemName = (isCrossDocking && filialCrossDocking?.nomeFilial) || filialItem?.nomeFilial || currentFilial.nomeFilial;

  const [oldValue, setOldValue] = useState(0);

  const handleFocus = (e) => {
    const { value: rawValue } = e.currentTarget;
    const parsedValue = +rawValue.replace(/\D/g, '');
    setOldValue(parsedValue);
  };

  const handleQuantity = (e) => {
    const { value: rawValue } = e.currentTarget;
    const parsedValue = +rawValue.replace(/\D/g, '');
    const stock = ((activeFilialItem && activeFilialItem.estoque) || 0) + othersStock;
    const value = parsedValue < stock ? parsedValue : stock;

    const quantityEnablePPl = ppl && value <= pplStock;

    if (ppl && value > pplStock) {
      enqueueSnackbar('Acima da quantidade permitida no PPL', {
        variant: 'warning',
      });
    }
    setFieldQtd(value);

    if (time) clearTimeout(time);
    setTime(setTimeout(() => {
      if (value == 0) {
        setFieldQtd(oldValue);
      }
      if (+value && value !== fieldQtd) {
        updateItem({
          productId,
          quantity: value,
          pplHash: quantityEnablePPl ? pplHash : null,
          warrantyId: valueRadioButton > 0 ? valueRadioButton : null,
          porcentagemDesconto: parseFloat(percent),
          total: subTotalOnTime,
        });
      }
    }, 1000));
  };

  const handleGarantia = (garantiaId) => {
    setValueRadioButton(garantiaId);
    const quantityEnablePPl = ppl && fieldQtd <= pplStock;
    updateItem({
      productId,
      quantity: fieldQtd,
      pplHash: quantityEnablePPl ? pplHash : null,
      warrantyId: garantiaId > 0 ? garantiaId : null,
      porcentagemDesconto: parseFloat(percent),
      total: subTotalOnTime,
    });
  };

  const [showModalGuarantee, setShowModalGuarantee] = useState(false);
  const handleMoreInfoGuarantee = () => setShowModalGuarantee(true);
  const handleCloseMoreInfoGuarantee = () => setShowModalGuarantee(false);

  const handleClose = async () => {
    setOpenModal(false);
  };

  const [modalEntregaQuebrada, setModalEntregaQuebrada] = useState(false);
  const [entregas, setEntregas] = useState([]);
  const handleOpenModal = () => {
    getEntregaQuebrada({checkoutId, idSku: productId}).then((res) => {
      if (res.data.content.length) {
        setModalEntregaQuebrada(true);
        setEntregas(res.data.content);
      } else {
        enqueueSnackbar('Nenhuma quebra de entrega encontrada', {
          variant: 'info',
        });
      }
    }).catch((e) => {
      enqueueSnackbar('Ocorreu um problema ao buscar quebra de entrega', {
        variant: 'error',
      });
    });
  };

  const handleCloseModal = () => {
    setModalEntregaQuebrada(false);
    setEntregas([]);
  };

  useEffect(() => {
    setFieldQtd(quantity);
  }, [quantity]);

  return (
    <Grid item lg={7} md={drawerIsOpened ? 12 : 6} sm={12}>
      <ModalEntregaQuebrada
        open={modalEntregaQuebrada}
        entregas={entregas}
        onClose={handleCloseModal}
      />
      <Tooltip title={codKit !== 0 ? 'Composição do produto pode conter destinos de diferentes estados!' : ''}>
        <div className={classes.productBox}>
          <Box
            display="flex"
            flex={1}
          >
            <img
              height={52}
              width={52}
              style={{ marginLeft: 8 }}
              src={`https://img.lojadomecanico.com.br/IMAGENS/${image}`}
              alt=""
            />

            <Box p={2} display="flex" style={{ padding: 0, paddingLeft: 8, paddingRight: 16 }}>
              <Box>
                <Typography variant="body2">
                  {name}

                  <Box pl={1} component="span" />

                  <StyledButton
                    size="small"
                    className={classes.productId}
                    onClick={(e) => {
                      e.stopPropagation();
                      showItem();
                    }}
                    variant="outlined"
                    color="primary"
                    disableElevation
                  >
                    {`Produto ${productId}`}
                  </StyledButton>

                  <Box pl={1} component="span" />

                  {nitro == true && (
                    <StyledButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal(true);
                      }}
                      className={classes.btnNitro}
                      variant="outlined"
                      color="secondary"
                      disableElevation
                    >
                      ENTREGA NITRO
                    </StyledButton>
                  )}

                  </Typography>

                  <Typography variant="body1" className={classes.model}>
                    {`Bseller ${idItemBseller} - ${model}`}
                  </Typography>

                  {idSeller && (
                    <Box>
                      {'Vendido e entregue por: '}
                      <span className={classes.companyName}>{companyName}</span>
                    </Box>
                  )}
                  {virtualStorage && (
                    <Box>
                      <img id={`tooltip${productId}`} onClick={(e) => {e.stopPropagation();}} src={tooltipEv} style={{position: 'absolute', left: 65, marginTop: -53, cursor: 'default', display: 'none' }} />
                      <StyledButton
                        size="small"
                        className={classes.productVirtual}
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          document.getElementById(`tooltip${productId}`).style.display = '';
                        }}
                        onMouseOver={(e) => {
                          document.getElementById(`tooltip${productId}`).style.display = '';
                        }}
                        onMouseOut={(e) => {
                          document.getElementById(`tooltip${productId}`).style.display = 'none';
                        }}
                        disableElevation
                        style={{borderColor: '#0075FF', paddingTop: 4 }}
                      >
                        PRODUTO VIRTUAL
                        <InfoOutlined style={{ color: '#0075FF', fontSize: 11, marginLeft: 4, position: 'relative', top: '-1px' }}  />
                      </StyledButton>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>

          <Dialog onClose={handleClose} open={openModal} maxWidth="sm">
            <DialogTitle position="relative">
              <Box position="absolute" top={4} right={4}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <img src={nitroImg} alt="nitro Logo" style={{ margin: 'auto' }} />
              </div>
              <div>
                <br />
                O serviço está disponível para alguns produtos e abrangências de CEP, conforme simulador de frete na página do carrinho de compras.
                <br />
                {' '}
                <br />
                A
                {' '}
                <b>Entrega Nitro</b>
                {' '}
                tem regras de medidas e pesos dos produtos, portanto seu pedido está sujeito a limitação para essa modalidade de entrega.
                <br />
                <br />
                Caso o pedido tenha 1 ou mais produtos não elegíveis para a modalidade Entrega Nitro, o pedido não terá a opção de Entrega Nitro, terá somente a opção de Entrega NORMAL.
                <br />
                <br />
                Como funciona:
                <br />
                <br />

                <div style={{ background: '#F3F4F6', padding: '10px' }}>
                  <ul>
                    <li>Entregas serão realizadas de segunda à sexta-feira em horário comercial.</li>
                    <li>Condição de pagamento em cartão de crédito e PIX (não é válido para pagamento de boleto bancário) e aprovadas até às 10h00 serão entregues no dia útil seguinte.</li>
                    <li>Compras aprovadas após às 10h00, acrescentar 1 dia útil ao prazo de entrega.</li>
                  </ul>
                </div>
                <br />
                Em casos de insucessos provocados por: cliente ausente, dados cadastrais incorretos, recusa pelo cliente ou funcionários de endereços residenciais e/ou comerciais, endereços comerciais fechados, os prazos de entrega poderão sofrer alterações.
                <br />
                <br />

              </div>
            </DialogContent>
          </Dialog>

          <CheckoutItemDialogGuarantee
            valueRadioButton={valueRadioButton}
            showModalGuarantee={showModalGuarantee}
            handleCloseMoreInfoGuarantee={handleCloseMoreInfoGuarantee}
            garantias={garantias}
            handleGarantia={handleGarantia}
          />

          {garantias.length > 0 && (
            <Box p={2} display="flex"  style={ !isMobile ? {marginLeft: '20px', marginRight: '45px', padding: 0} : {width: '100%'} }>
              <Box>
                <RadioGroup className={classes.radioGuarantee}>
                  <FormControlLabel className={`${classes.selectGuarantee} ${valueRadioButton===0 ? classes.selectedGuarantee : ""}`}
                    value="0"
                    checked={valueRadioButton===0}
                    onChange={(e) => handleGarantia(0)}
                    control={<Radio className={classes.radioRecusa} />}
                    label="Garantia de fábrica"
                  />
                  {garantias.map((item) => (
                    <FormControlLabel
                      className={`${classes.selectGuarantee} ${valueRadioButton===item.idGarantia ? classes.selectedGuarantee : ""}`}
                      value={item.idGarantia}
                      checked={valueRadioButton===item.idGarantia}
                      onChange={(e) => handleGarantia(item.idGarantia)}
                      control={<Radio className={classes.radioRecusa} />}
                      label={"+"+item.descricaoGarantia.replace("GARANTIA", "").replace("MESES", "meses")+" - "+toMoney(item.garantiaEstendidaValorTotal.valorGarantiaOfertadaXQuantidadeCarrinho)}
                    />
                  ))}
                  <Box>
                    <Button
                      className={classes.btnMoreInfo}
                      variant="text"
                      color="tertiary"
                      onClick={handleMoreInfoGuarantee}
                    >
                      <InfoOutlined fontSize="small"/>
                      Saiba mais
                    </Button>
                  </Box>
                </RadioGroup>
              </Box>
            </Box>
          )}

          {!isSingleDelivery && (
            <Tooltip title={"Quebra de Entrega"}>
              <IconButton
                className={classes.stockIcon}
                onClick={handleOpenModal}
              />
            </Tooltip>
          )}

          <Tooltip
            title={`
              ${isSingleDelivery && fieldQtd > filialItemStock ? `NÃO HÁ ESTOQUE DISPONIVEL | TOTAL: ${filialItemStock} | ` : ''} 
              ${filialItemName}: 
              ${filialItemStock ? filialItemStock : 0}
              | OUTROS: ${othersStock}${ppl ? ` | ESTOQUE MÁXIMO PPL: ${pplStock}` : ''}
            `}
          >
            <TextField
              disabled={loading || !!orderId}
              className={classes.inputQtd}
              label="Qtd."
              size="small"
              name="quantity"
              variant="outlined"
              value={toThousand(fieldQtd)}
              onChange={handleQuantity}
              onFocus={handleFocus}
              InputProps={{
                inputProps: {
                  maxLength: 9,
                  style: (loading ? { color: 'transparent' } : {}),
                },
                endAdornment: ((isSingleDelivery && fieldQtd > filialItemStock) || (ppl && fieldQtd > pplStock)) && (
                <InputAdornment position="end">
                  <ErrorIcon className={classes.warningIcon} />
                </InputAdornment>
                ),
              }}
            />

          </Tooltip>

        </div>
      </Tooltip>
    </Grid>
  );
};

CheckoutItemDetails.defaultProps = {
  companyName: null,
  idSeller: null,
  ppl: false,
  pplHash: null,
  pplStock: null,
  orderId: null,
};

CheckoutItemDetails.propTypes = {
  productId: number.isRequired,
  name: string.isRequired,
  image: string.isRequired,
  codKit: number,
  quantity: number.isRequired,
  subTotalOnTime: number.isRequired,
  updateItem: func.isRequired,
  drawerIsOpened: bool.isRequired,
  virtualStorage: bool.isRequired,
  loading: bool.isRequired,
  ppl: bool,
  pplHash: string,
  pplStock: number,
  enqueueSnackbar: func.isRequired,
  showItem: func.isRequired,
  companyName: string,
  idSeller: oneOfType([string, number]),
  model: string.isRequired,
  idItemBseller: number.isRequired,
  filials: arrayOf(instanceOf(Object)).isRequired,
  activeFilial: instanceOf(Object).isRequired,
  percent: oneOfType([string, number]).isRequired,
  orderId: number,
  garantias: arrayOf(instanceOf(Object)),
  isSingleDelivery: bool.isRequired,
  checkoutId: number.isRequired,
  idSku: number.isRequired,
  currentFilial: instanceOf(Object).isRequired,
  typeStock: string.isRequired
};

export default CheckoutItemDetails;
